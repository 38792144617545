import Vue from "vue";
import Vuex from "vuex";
import myGetters from "./getter";
import app from "./modules/app";
import writeHeader from "./modules/writeHeader";
import shareHeader from "./modules/shareHeader";
import metaArticleList from "./modules/metaArticleList";
import metaBlock from "./modules/metaBlock";
import metaIdDataView from "./modules/metaIdDataView";
import metaBlockUserInfo from "./modules/metaBlockUserInfo";
import mcBlock from "@/store/modules/mcBlock";
import mcDetail from "@/store/modules/mcDetail";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    writeHeader,
    shareHeader,
    metaArticleList,
    metaBlock,
    metaIdDataView,
    metaBlockUserInfo,
    mcBlock,
    mcDetail,
  },
  getters: myGetters,
});
