export default {
  queryFindMetaData: "/api/showMANDB/api/v1/query/queryFindMetaData/",
  getMetaImageData: "/api/showMANDB/api/v1/metanet/getMetaImageData/",
  getMetaIDInfo: "/api/showMANDB/api/v1/query/getMetaIDInfo/",
  getMetaIDInfoBatch: "/api/showMANDB/api/v1/query/getMetaIDInfoBatch",
  getToken: "/showmoney/oauth2/oauth/token",
  getMetaIdInfoV2: "/api/metaid/v2/app/metaid/info",
  getMetablockinfoV2: "/api/metaid/v2/app/metaid/metablockinfo",
  getMetaBlockTxV2: "/api/metaid/v2/app/metaid/tx",
  getMetaBlockWithUserV2: "/api/metaid/v2/app/metaid/getuser",
  getMetaCoinBlock: "/api/metaid/v2/app/metaid/blocks",
  getMetaCoinHistory: "/api/metaid/v2/app/metaid/history",
  getMCInfoWithAddress: "https://api.sensiblequery.com/ft/summary/",
  getOpenMetaBlock:"/api/metaid/v2/app/metaid/airdropMetablock"
};
