




















import { Component, Vue } from "vue-property-decorator";
import MetaIdAllView from "@/views/homeComponent/MetaIdAllView.vue";
import MetaBlockNewTx from "@/views/homeComponent/MetaBlockNewTx.vue";
import MetaUserData from "@/views/homeComponent/MetaUserData.vue";
import SearchInput from "@/components/common/SearchInput.vue";
import { appDispath } from "@/store/modules/app";

@Component({
  name: "Home",
  components: {
    MetaIdAllView,
    MetaBlockNewTx,
    MetaUserData,
    SearchInput,
  },
})
export default class Home extends Vue {
  list: any = {};
  intersectOp: { handler: Function; options?: IntersectionObserverInit } = {
    handler: this.onIntersect,
    options: {
      rootMargin: "-70px 0px 0px 0px",
    },
  };
  onIntersect(entries: any, observer: any, isIntersecting: any) {
    this.$store.dispatch(appDispath.updateTabList, entries[0]);
    if (isIntersecting) {
    }
  }
}
