import zango from "zangodb";
const MyZangoDb = {};
MyZangoDb.install = function(Vue, options) {
  let db, myUserCollection, myTxCollection, myArtCollection;
  const initDb = function(
    options = {
      dbName: "metaBlogDb",
      config: {
        userCollection: ["metaId"],
        txCollection: ["txId"],
        artCollection: ["metaId"],
      },
      collectionName: "userCollection",
      txCollectionName: "txCollection",
      artCollectionName: "artCollection",
    }
  ) {
    db = new zango.Db(options.dbName, options.config);
    myUserCollection = db.collection(options.collectionName);
    myTxCollection = db.collection(options.txCollectionName);
    myArtCollection = db.collection(options.artCollectionName);
  };
  const myIndexZgDb = {
    db: db,
    userCol: myUserCollection,
    txCol: myTxCollection,
    artCol: myArtCollection,
    userData: {
      insertData: async (datas) => {
        let insertDatas = datas;
        if (!(datas instanceof Array)) {
          insertDatas = [datas];
        }
        for (let key in insertDatas) {
          let item = insertDatas[key];
          const findR = await myUserCollection.findOne({
            metaId: item.txId || item.metaId,
          });
          if (findR) {
            await myUserCollection.update(
              { metaId: item.txId || item.metaId },
              item
            );
          } else {
            await myUserCollection.insert(item);
          }
        }
      },
      findTxData: (txId) => {
        return myUserCollection.findOne({ metaId: txId });
      },
      findData: (params) => {
        const findResult = myUserCollection.find(params.find);
        return findResult.toArray();
      },
    },
    artData: {
      insertData: async (datas) => {
        let insertDatas = datas;
        if (!(datas instanceof Array)) {
          insertDatas = [datas];
        }
        for (let key in insertDatas) {
          let item = insertDatas[key];
          const findR = await myArtCollection.findOne({
            metaId: item.txId || item.metaId,
            uuid: item.uuid,
          });
          if (findR) {
            await myArtCollection.update(
              { metaId: item.txId || item.metaId, uuid: item.uuid },
              item
            );
          } else {
            await myArtCollection.insert(item);
          }
        }
      },
      findTxData: (txId) => {
        return myArtCollection.findOne({ metaId: txId });
      },
      findData: (params) => {
        const findResult = myArtCollection.find(params.find);
        return findResult.toArray();
      },
      removeData: (params) => {},
    },
    txData: {
      insertData: async (datas) => {
        let insertDatas = datas;
        if (!(datas instanceof Array)) {
          insertDatas = [datas];
        }
        for (let key in insertDatas) {
          let item = insertDatas[key];
          const findR = await myTxCollection.findOne({
            txId: item.txId || item.metaId,
          });
          if (findR) {
            await myTxCollection.update(
              { txId: item.txId || item.metaId },
              item
            );
          } else {
            await myTxCollection.insert(item);
          }
        }
      },
      findTxData: (txId) => {
        return myTxCollection.findOne({ txId: txId });
      },
      findData: (params) => {
        const findResult = myTxCollection.find(params.find);
        return findResult.toArray();
      },
    },
  };
  initDb();
  Vue.myZangoDb = function(options) {
    if (options) {
      this.initDb(options);
    }
    return myIndexZgDb;
  };
  Vue.prototype.$myIndexZgDb = myIndexZgDb;
};

export default MyZangoDb;
