












































import { goAuth } from "@/api/api_helper";
import { UserInfoTypes } from "@/metaid-types";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import MyAvatar from "./Avatar.vue";
@Component({
  name: "LoginArea",
  components: {
    MyAvatar,
  },
})
export default class LoginArea extends Vue {
  @Getter("isLogined") isLogined!: boolean;
  @Getter("isApp") isApp!: boolean;
  @Getter("currentUser") currentUser!: UserInfoTypes;
  @Getter("app/userLogining") userLogining!: boolean;

  menuSelected: any = undefined;
  listItems: Array<any> = [
    {
      title: "exit",
      handler: () => {
        this.logoutApp();
      },
    },
  ];
  handleMenuClick(item: any) {
    item.handler();
  }
  loginout() {}
  loginHandle() {
    goAuth();
  }
}
