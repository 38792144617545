

























































































import { Component, Vue } from "vue-property-decorator";
const dotIcon = require("@/assets/image/dot@2x.png");
import ItemBlock from "./ItemBlock.vue";
import MetaBlockTable from "@/views/homeComponent/MetaBlockTable.vue";
import MetaBlockDataLine from "@/views/homeComponent/MetaBlockDataLine.vue";
import { MetaBlockInfo, MetaBlockTablePath } from "@/store/modules/metaBlock";
import { Getter } from "vuex-class";

@Component({
  name: "MetaBlockNewTx",
  components: {
    ItemBlock,
    MetaBlockTable,
    MetaBlockDataLine,
  },
})
export default class MetaBlockNewTx extends Vue {
  @Getter("metaBlock/currentMetaBlock") metaBlock!: MetaBlockInfo;
  dotIcon: any = dotIcon;
  get metaBlockCount() {
    const dayOutBlock = 144;
    // this.metaBlock.nextmetablockcount = 143; //测试
    if (this.metaBlock.nextmetablockcount) {
      return (
        ((dayOutBlock - this.metaBlock.nextmetablockcount) / dayOutBlock) * 100
      );
    } else {
      return 0;
    }
  }
  get metaBlockMin() {
    if (this.metaBlock.nextmetablockcount) {
      let min: string = (this.metaBlock.nextmetablockcount * 10).toFixed(0);
      if (min == "0") {
        min = "1";
      }
      return Number(min);
    } else {
      return 0;
    }
  }
  get currentMetaBlockCirculation() {
    let result = 50;
    const height = Math.floor(this.metaBlock.metablockheight / 800);
    for (let i = height; i > 0; i--) {
      result = result / 2;
    }
    return result;
  }

  mounted() {
    this.$store.dispatch(MetaBlockTablePath.getCurrentMetaBlock);
  }
  toMcDetail() {
    this.$router.push({
      name: "mcDetail",
    });
  }
}
