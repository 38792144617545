


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "MetaBlockDataLine",
})
export default class MetaBlockDataLine extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  value!: number;
  @Prop({
    type: Number,
  })
  blocks!: number;
  @Prop({
    type: Number,
  })
  mins!: number;
}
