




























import { Component, Prop, Vue } from "vue-property-decorator";
const searchIcon = require("@/assets/image/icon_search@2x.png");

@Component({
  name: "SearchInput",
})
export default class SearchInput extends Vue {
  searchKey: string = "";
  searchIcon: any = searchIcon;
  valueChange(e: any) {
    debugger;
  }

  @Prop({
    type: String,
    default: "",
  })
  pholder!: string;
}
