
















import config from "@/config";
import { Component, Vue, Prop, Provide, Ref } from "vue-property-decorator";
@Component({
  components: {},
  name: "MyAvatar",
})
export default class MyAvatar extends Vue {
  @Ref("avatarImg") readonly avatarImg: any;
  @Prop({
    type: String,
    default: "",
  })
  tx!: string;
  @Prop({
    type: String,
    default: "",
  })
  src!: string;

  @Prop({
    type: Number,
    default: 32,
  })
  size!: number;

  @Prop({
    default: true,
  })
  rounded!: boolean | string;

  @Prop({
    default: false,
  })
  tile!: boolean;
  $refs!: {
    avatarImg: HTMLFormElement;
  };
  defaultAvatar =
    "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png";
  //   get inlineStyle() {
  //     const styleObj: any = {
  //       width: this.size + "px",
  //       height: this.size + "px",
  //     };
  //     if (this.shape === "circle") {
  //       styleObj["border-radius"] = "50%";
  //     }
  //     return styleObj;
  //   }
  get avatarUrl() {
    let avatar = this.defaultAvatar;
    if (this.src && this.src !== "") {
      avatar = this.src;
    } else if (this.tx && this.tx !== "") {
      avatar = `${config.metaFileServiceUrl}/metafile/${this.tx}`;
    }
    return avatar;
  }
  mounted() {
    // this.$nextTick(() => {
    //   const img = this.$refs.avatarImg;
    //   img.onload = (e) => {
    //     if (img.naturalHeight < img.naturalWidth) {
    //       img.style.width = "";
    //       img.style.height = "100%";
    //       img.style.maxHeight = "100%";
    //       img.style.maxWidth = "";
    //     } else {
    //       img.style.width = "100%";
    //       img.style.height = "";
    //       img.style.maxHeight = "";
    //       img.style.maxWidth = "100%";
    //     }
    //   };
    // });
  }
}
