import { getMetaAccessContentList } from "@/api/api_helper";
const _tabList = [
  {
    label: "热门文章",
    key: "hot",
  },
  {
    label: "付费最多",
    key: "more",
  },
  {
    label: "最新文章",
    key: "new",
  },
];
export const state = () => ({
  artList: [
    // "95bf1e854bcd8b7ec5aaafef8faf5b7373d03c3f649f78978b4415c7e9236f4e",
    // "95bf1e854bcd8b7ec5aaafef8faf5b7373d03c3f649f78978b4415c7e9236f4e",
  ],
  pageSize: 10,
  total: 0,
  articleType: _tabList[0].key,
  tabList: _tabList,
  loading: false,
  page: 1,
  finish: false,
  scrollHeight: 0,
  check: false,
  fetchTime: 0,
});

export const getters = {
  artList: (state: any): Array<any> => {
    return state.artList;
  },
  total: (state: any) => {
    return state.total;
  },
  articleType: (state: any) => {
    return state.articleType;
  },
  tabList: (state: any) => {
    return state.tabList;
  },
  loading: (state: any) => {
    return state.loading;
  },
  finish: (state: any) => {
    return state.finish;
  },
  scrollHeight: (state: any) => {
    return state.scrollHeight;
  },
  check: (state: any) => {
    return state.check;
  },
  fetchTime: (state: any) => {
    return state.fetchTime;
  },
};

export const mutations = {
  updateArtList: (state: any, payload: any) => {
    state.artList = [...state.artList, ...payload];
    if (state.artList.length < state.total) {
      state.page++;
    }
  },
  updateArticleType: (state: any, payload: any) => {
    if (payload) {
      state.articleType = payload;
    }
    // state.check = false;
    // state.artList = [];
    // state.total = 0;
    // state.fetchTime = 0;
    // state.page = 1;
    // // state.loading = false;
    // state.finish = false;
  },
  updateLoading: (state: any, payload: any) => {
    state.loading = payload;
  },
  updateFinish: (state: any, payload: any) => {
    state.finish = payload;
  },
  updateScrollHeight: (state: any, payload: any) => {
    state.scrollHeight = payload;
  },
};
export const MetaArticleListPath = {
  updateActionValue: "metaArticleList/updateActionValue",
  loadMetaArticleDataAction: "metaArticleList/loadMetaArticleDataAction",
  updateArticleType: "metaArticleList/updateArticleType",
  updateLoading: "metaArticleList/updateLoading",
  updateFinish: "metaArticleList/updateFinish",
  updateScrollHeight: "metaArticleList/updateScrollHeight",
};
export const actions = {
  updateArtList({ commit }: { commit: Function }, payload: any) {
    commit("updateArtList", payload);
  },
  updateArticleType({ commit }: { commit: Function }, payload: any) {
    commit("updateArticleType", payload);
  },
  /**
   *
   * @param param0
   * @param payload
   */
  loadMetaArticleDataAction(
    { commit, state }: { commit: Function; state: any },
    payload: any
  ) {
    if (state.loading) {
      return;
    }
    const params: any = {
      find: {},
      skip: state.page,
    };
    if (payload && payload.rootTxId) {
      params.find["rootTxId"] = payload.rootTxId;
    }
    state.loading = true;
    const successCallback = (r: any) => {
      console.log("getMetaAccessContentList:", r);
      state.loading = false;
      state.fetchTime++;
      // debugger;
      if (r && r.data) {
        state.total = r.total;
        commit("updateArtList", r.data);
        // commit("updateArtList", []);
      }
    };
    const errorCallback = (err: any) => {
      console.log("getMetaAccessContentList 出错", err);
      return null;
    };
    // TODO:需要根据articleType选择不同调用方法

    switch (state.articleType) {
      case _tabList[0].key:
        getMetaAccessContentList(params)
          .then(successCallback)
          .catch(errorCallback);
        break;
      case _tabList[1].key:
        getMetaAccessContentList(params)
          .then(successCallback)
          .catch(errorCallback);
        break;
      case _tabList[2].key:
        getMetaAccessContentList(params)
          .then(successCallback)
          .catch(errorCallback);
        break;
      default:
        getMetaAccessContentList(params)
          .then(successCallback)
          .catch(errorCallback);
    }
  },
  updateLoading({ commit }: { commit: Function }, payload: any) {
    commit("updateLoading", payload);
  },
  updateFinish({ commit }: { commit: Function }, payload: any) {
    commit("updateFinish", payload);
  },
  updateScrollHeight({ commit }: { commit: Function }, payload: any) {
    commit("updateScrollHeight", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
