import { TokenObjType } from "@/metaid-types";
import axios, { AxiosInstance } from "axios";
import qs from "qs";
import api_url from "./api_url";
import Vue from "vue";
import MyZangoDb from "@/plugins/zangoDb.js";
import _ from "lodash";
// import { Toast } from "vant";
Vue.use(MyZangoDb);
const myIndexZgDb = Vue.prototype.$myIndexZgDb;
const axiosInstance: AxiosInstance = axios.create();

/**
 * @description: 枚举出请求数据格式类型
 * @param {type} 枚举类型
 * @return:
 */
export enum ContentType {
  json = "application/json;charset=UTF-8",
  form = "application/x-www-form-urlencoded; charset=UTF-8",
}
/**
 * @description: 枚举request请求的method方法
 * @param {type} 枚举类型
 * @return:
 */
enum HttpMethod {
  get = "GET",
  post = "POST",
}

const EXPIRE_TIME = 30000;
let pedding: any = {};
const createReqStr = (config: any) => {
  let reqStr;
  if (config.method === "get") {
    reqStr = config.url + config.method;
  }
  if (config.method === "post") {
    reqStr =
      config.url +
      config.method +
      (typeof config.data == "string"
        ? config.data
        : JSON.stringify(config.data));
  }
  return window.btoa(reqStr);
};
const createCacheResponseData = (promise: Promise<any>) => {
  return {
    promise: promise,
    time: Date.now(),
  };
};
/**
 * 请求前拦截
 */
axiosInstance.interceptors.request.use(
  (reqConfig) => {
    console.log("axiosInstance.interceptors.request", reqConfig);
    // Toast.loading({
    //   forbidClick: true,
    // });
    return reqConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);
/**
 * 请求返回拦截
 */
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      return response.data;
    }
  },
  (err) => {
    // Toast.clear();
    // Vue.prototype.$toasted.error(err);
    const _vue = Vue
    console.log("vue", _vue)
    _.debounce((_err) => {
      console.error(_err.message)
    },1000)(err)
    return Promise.reject(err);
  }
);

const handleRequestPromise = (options: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let key = createReqStr({
        url: options.url,
        method: options.method,
        data: options.data,
      });
      let requests = pedding[key];
      let axiosInstanceConfig: any = {
        method: options.method,
        url: options.url,
      };
      if (options.method == "post") {
        axiosInstanceConfig["data"] = options.data;
      }
      if (options.transformRequest) {
        axiosInstanceConfig["transformRequest"] = options.transformRequest;
      }
      if (options.headers) {
        axiosInstanceConfig["headers"] = options.headers;
      }
      if (requests) {
        pedding[key].push(axiosInstanceConfig);
        let reponsenData = pedding[key][0];
        if (reponsenData.time + EXPIRE_TIME > Date.now()) {
          //可用 response
          reponsenData.promise
            .then((res: any) => {
              resolve(res);
            })
            .catch((err: any) => {
              reject(err);
            });
        } else {
          reponsenData = createCacheResponseData(
            axiosInstance(axiosInstanceConfig)
          );
          pedding[key] = [reponsenData];
          reponsenData.promise
            .then((res: any) => {
              resolve(res);
            })
            .catch((err: any) => {
              reject(err);
            });
          //不可用 response
        }
      } else {
        let reponsenData = createCacheResponseData(
          axiosInstance(axiosInstanceConfig)
        );
        pedding[key] = [reponsenData];
        reponsenData.promise
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getData = (url: string, params?: any) => {
  // if (params) {
  //   url = url + params;
  // }
  return axiosInstance({
    url: url,
    method: HttpMethod.get,
    data: params,
  });
};
export const postData = (url: string, params: any) => {
  return axiosInstance({
    url: url,
    method: HttpMethod.post,
    data: params,
  });
};

/**
 * 查询metaData
 * @param params
 */
export const queryFindMetaData = (params: any) => {
  const paramsB64 = btoa(JSON.stringify(params));
  return getData(api_url.queryFindMetaData + paramsB64);
};
/**
 * 获取metaImage
 * @param params
 */
export const getMetaImageData = (params: any) => {
  return getData(api_url.getMetaImageData, params);
};
// 获取第三方登录token
export const getToken = (params: any) => {
  return axiosInstance({
    method: HttpMethod.post,
    headers: {
      "Content-Type": ContentType.form,
    },
    url: api_url.getToken,
    data: params,
    transformRequest: [
      function(data) {
        return qs.stringify(data);
      },
    ],
  });
};
/**
 * 批量获取metaid用户信息
 * @param params
 */
export const getAllShowAccount = (txids: Array<string>) => {
  const params = {
    data: {
      metaIds: txids,
    },
  };
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaIDInfoBatch, params).catch(
      (err: Error) => {
        console.error("getMetaIDInfo err:", err);
        return { error: err.message };
      }
    );
    if (r.code == 200) {
      return resolve(r.result);
    } else {
      return reject(r.error);
    }
  });
};
/**
 * 获取指定metaid用户信息
 * @param params
 */
export const getShowAccount = (txid: string) => {
  return new Promise(async (resolve, reject) => {
    const user = await myIndexZgDb.userData.findTxData(txid);
    if (user) {
      resolve(user);
      return;
    }
    const r: any = await getData(api_url.getMetaIDInfo + txid).catch(
      (err: Error) => {
        console.error("getMetaIDInfo err:", err);
        return { error: err.message };
      }
    );
    if (r.code == 200) {
      myIndexZgDb.userData.insertData([r.result]);
      return resolve(r.result);
    } else {
      return reject(r.error);
    }
  });
};
export const updateAllAccount = () => {
  return new Promise(async (resolve, reject) => {
    const r: Array<any> = await myIndexZgDb.userData.findData({
      find: {},
    });
    const txids: Array<any> = [];
    r.forEach((item: any) => {
      txids.push(item.metaId);
    });
    if (txids.length == 0) {
      resolve(txids);
      return;
    }
    const allUserResult: any = await postData(api_url.getMetaIDInfoBatch, {
      data: {
        metaIds: txids,
      },
    }).catch((err) => {
      resolve(txids);
      return;
    });
    if (allUserResult && allUserResult.code == 200) {
      await myIndexZgDb.userData.insertData(allUserResult.result.data);
      resolve(txids);
      return;
    } else {
      resolve(txids);
      return;
    }
  });
};
export const getArticlePublicKey = (url: string) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await getData(url + "getPublicKey").catch((err) =>
      reject(err)
    );
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
export const getCoinInfoWithAddress  = (address:string) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await getData(api_url.getMCInfoWithAddress + address).catch((err) =>
      reject(err)
    );
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
export const getArticleDecryptMessage = (url: string, params: any) => {
  return new Promise(async (resolve, reject) => {
    console.log("getMessage params:", JSON.stringify(params));
    const r: any = await postData(url + "getMessage", params).catch((err) => {
      reject(err);
      return err;
    });
    if (r.code == 0) {
      resolve(r.data.message);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};

