








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'MySvg',
})
export default class MySvg extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  iconClass: string | undefined;

  @Prop({
    type: Number,
  })
  size: number | undefined;

  @Prop({
    type: String,
    default: 'circle',
  })
  shape: string | undefined;

  @Prop({
    type: Number,
  })
  width: number | undefined;
  @Prop({
    type: Number,
  })
  height: number | undefined;

  get iconName() {
    return `#icon-${this.iconClass}`
  }

  get initStyle() {
    const initStyle = {
      width: '100%',
      height: '100%',
    }
    if (this.size) {
      initStyle.width = this.size + 'px'
      initStyle.height = this.size + 'px'
    }
    if (this.width) {
      initStyle.width = this.width + 'px'
    }
    if (this.height) {
      initStyle.height = this.height + 'px'
    }
    return initStyle
  }
}
