import config from "@/config";
import { TokenObjType } from "@/metaid-types";
// import { Toast } from "vant";
import { getToken, postData, queryFindMetaData } from ".";
import _ from "lodash";
import api_url from "./api_url";

/**
 *
 * @param code
 */
export const getAccessToken = (code: string) => {
  return new Promise(async (resolve, reject) => {
    const res = await getToken({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: config.oauthSettings.redirectUri,
      scope: "app",
      client_id: process.env.VUE_APP_clientId,
      client_secret: process.env.VUE_APP_clientSecret,
    }).catch((err) => {
      //授权出错处理
      reject(err);
    });
    if (res) {
      resolve(res);
    }
  });
};
/**
 *
 * @param refreshToke
 */
export const getRefreshToken = (refreshToke: string): any => {
  return new Promise(async (resolve, reject) => {
    const res: any = await getToken({
      grant_type: "refresh_token",
      refresh_token: refreshToke,
      client_id: process.env.VUE_APP_clientId,
      client_secret: process.env.VUE_APP_clientSecret,
    }).catch((err: any) => {
      reject(err);
    });
    resolve(res);
  });
};

export const goAuth = () => {
  const authUrl = `${process.env.VUE_APP_SHOW_MONEY}/userLogin?response_type=code&scope=app&client_id=${process.env.VUE_APP_clientId}&redirect_uri=${process.env.VUE_APP_redirectUri}`;
  window.location.replace(authUrl);
};

export const getQueryFindMetaData = (params: any) => {
  return new Promise(async (resolve, reject) => {
    // if (!params.find.metaId) {
    //   params.find.metaId = config.metaIdTag;
    // }
    const defParams = {
      find: {
        metaId: config.metaIdTag,
      },
      sort: {
        timestamp: -1,
      },
    };
    const _params = _.mergeWith(defParams, params, function(
      objValue: any,
      srcValue: any,
      key: any,
      object: any,
      source: any,
      stack: any
    ) {
      if (objValue == undefined) {
        object[key] = srcValue;
        return object[key];
      }
    });
    console.log("getQueryFindMetaData mergeWith param:", _params);
    const res: any = await queryFindMetaData(_params).catch((err) => {
      reject(err);
    });
    if (res.code == 200) {
      resolve(res.result);
    } else {
      // Toast.fail(res.error);
      reject(res.error);
    }
  });
};

export const getPayLikes = (txid: string) => {
  const params = {
    find: {
      metaId: config.metaIdTag,
      "data.likeTo": txid,
    },
    limit: -1,
  };
  return getQueryFindMetaData(params);
};
export const getTxData = (txid: string) => {
  const params = {
    find: {
      txId: txid,
      // metaId: config.metaIdTag,
    },
  };
  return getQueryFindMetaData(params);
};
export const getPayComment = (txid: string) => {
  const params = {
    find: {
      metaId: config.metaIdTag,
      "data.commentTo": txid,
    },
    limit: -1,
  };
  return getQueryFindMetaData(params);
};
// export const getTxIdData = (txid: string) => {
//   const params: any = {
//     find: {
//       txId: txid,
//     },
//   };
//   return getQueryFindMetaData(params);
// };
export const getAllMetaAccessServiceConfig = (op?: any) => {
  const params: any = {
    find: {
      parentNodeName: "MetaAccessServiceConfig",
      isNew: true,
      "data.metaID": {
        $ne: "1",
      },
    },
  };
  return getQueryFindMetaData(params);
};
export const getMetaAccessContentList = (op?: any) => {
  const params: any = {
    find: {
      parentNodeName: "MetaAccessContent",
      isNew: true,
    },
  };
  const _params = _.mergeWith(params, op, function(
    objValue: any,
    srcValue: any,
    key: any,
    object: any,
    source: any,
    stack: any
  ) {
    if (objValue == undefined) {
      object[key] = srcValue;
      return object[key];
    }
  });
  return getQueryFindMetaData(params);
};

export const getNewTxData = (tx: any, timeDelay = 400, times = 5) => {
  return new Promise((resolve, reject) => {
    var time = 0;
    var timer = setInterval(() => {
      console.log("getNewTxData time:", Date.now());
      getTxData(tx).then((res: any) => {
        console.log("getNewTxData time res:", res, Date.now());
        if (res && res.data.length > 0) {
          clearInterval(timer);
          resolve(res);
        } else {
          if (time >= times) {
            clearInterval(timer);
            resolve([]);
          } else {
            time++;
          }
        }
      });
    }, timeDelay);
  });
};
/**
 * 查找用户的Info MetaData
 * @param tx
 */
export const getUserInfoMetaData = (tx: any) => {
  return getQueryFindMetaData({
    find: {
      nodeName: "Info",
      rootTxId: tx,
    },
  });
};

/**
 * 获取MetaIDInfo
 * @param metaid
 * @returns
 */
export const getMetaIdInfoV2 = (metaid: string = "") => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaIdInfoV2, { metaid: metaid });
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
/**
 *
 * @param metaid
 * @returns
 */
export const getMetablockinfoV2 = (metaid: string = "") => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetablockinfoV2, {
      metaid: metaid,
    });
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
export const getMetaBlockTxV2 = (params: any) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaBlockTxV2, params);
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
export const getMetaBlockWithUserV2 = (params: any) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaBlockWithUserV2, params);
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
/**
 * 获取metaCoin块数据
 * @param params
 * @returns
 */
export const getMetaCoinBlocks = (params: any): Promise<Array<any>> => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaCoinBlock, params);
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};
export interface MetaCoinHistoryGetParams {
  metaid: string;
  page: number;
  pagesize: number;
}
/**
 * 获取metaCoin历史
 * @param params
 * @returns
 */
export const getMetaCoinHistory = (params: MetaCoinHistoryGetParams) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getMetaCoinHistory, params);
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};




export const getOpenMetaBlock = (params: {  }) => {
  return new Promise(async (resolve, reject) => {
    const r: any = await postData(api_url.getOpenMetaBlock, params);
    if (r.code == 0) {
      resolve(r.data);
    } else {
      if (r.error) {
        reject(r.error);
      } else {
        reject(r.msg);
      }
    }
  });
};

