import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import { appDispath } from "@/store/modules/app";
import { PositionResult } from "vue-router/types/router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "",
      keepAlive: false,
      userNeedLogin: false,
      top: false,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "",
      keepAlive: false,
      userNeedLogin: false,
      top: true,
    },
  },
  {
    path: "/mcRecord",
    name: "mcRecord",
    component: () => import("../views/mcData/mc.vue"),
    meta: {
      title: "to_mc_record",
      keepAlive: false,
      userNeedLogin: true,
      top: true,
    },
  },
  {
    path: "/mcDetail",
    name: "mcDetail",
    component: () => import("../views/mcData/metaCoinDetail.vue"),
    meta: {
      title: "to_mc_detail",
      keepAlive: false,
      userNeedLogin: true,
      top: true,
    },
  },
  {
    path: "*",
    component: Home,
    meta: {
      title: "",
      keepAlive: false,
      userNeedLogin: false,
      top: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to: Route, from: Route, savePosition: PositionResult) => {
    if (to.meta.top) {
      return { x: 0, y: 0 };
    } else {
      return savePosition;
    }
  },
});

router.beforeEach((to: any, from: any, next: any) => {
  // if (from.meta.keepAlive && from.name == "Home") {
  //   const $listWrap = document.querySelector(".listWrap");
  //   const scrollTop = $listWrap ? $listWrap.scrollTop : 0;
  //   from.meta.scrollTop = scrollTop;
  // }
  console.log("router store:", store);
  console.log("是否 有Token:", store.getters.isToken);
  console.log("是否 已登录:", store.getters.isLogined);
  console.log("form to:", from, to);
  if (to.meta.userNeedLogin) {
    if (store.getters.isToken) {
      if (!store.getters.isLogined) {
        store.commit(appDispath.updateAutoLogining, true);
        store.watch(
          (state: any, getters: any) => {
            return getters.isLogined;
          },
          (isLogined) => {
            console.log("路由已登录？", isLogined);
            store.commit(appDispath.updateAutoLogining, false);
            if (isLogined) {
              next();
            }
          }
        );
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
