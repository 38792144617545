import { getArticleDecryptMessage, getShowAccount } from "@/api";
import {
  getPayLikes,
  getPayComment,
  getTxData,
  getQueryFindMetaData,
} from "@/api/api_helper";
import config from "@/config";
import {
  PayToItemInterface,
  SendMetaDataTxParams,
  UserInfoTypes,
} from "@/metaid-types";
import MetaProtocol, {
  PayCommentProtocolInterface,
  PayLikeProtocolInterface,
} from "./metaProtocol";
import MetaSdk from "./metaSdk";

export default class MetaIdData {
  txId: any;
  metaData: any;
  metaSdk: MetaSdk | null = null;
  metaProtocol: MetaProtocol | null = null;
  likes: Array<any> = [];
  comments: Array<any> = [];
  userInfo: UserInfoTypes | null = null;
  vue: Vue;
  metaAccessServiceConfigMetaMetaData: any = null;
  metaAccessPayData: any = null;
  metaAccess: any = null;
  currentUser: UserInfoTypes | null = null;
  currentUserMeataAccessPay: any = null;
  simpleSubData: Array<any> = [];
  isMyArt: boolean = false;
  constructor(
    metaData: any,
    metaSdk: MetaSdk,
    metaProtocol: MetaProtocol,
    vue: Vue
  ) {
    console.log("MetaIdData constructor run");
    this.metaData = metaData;
    this.txId = metaData.txId;
    if (metaSdk) {
      this.metaSdk = metaSdk;
    }
    if (metaProtocol) {
      this.metaProtocol = metaProtocol;
    }
    this.vue = vue;
    const $store: any = this.vue.$store;
    if (!$store.state.app.currentUser) {
      const watchCurrentUser: any = this.vue.$store.watch(
        (state, getters) => {
          console.log("watchCurrentUser sub");
          return state.app.currentUser;
        },
        (val, oldval) => {
          console.log("watchCurrentUser sub result");
          this.currentUser = val;
          this.isMyArt = this.metaData.rootTxId == this.currentUser?.metaId;
          watchCurrentUser();
        }
      );
    } else {
      this.currentUser = $store.state.app.currentUser;
    }
  }
  /**
   *设置metaSdk
   * @param metaSdk
   */
  setMetaSdk(metaSdk: MetaSdk) {
    this.metaSdk = metaSdk;
  }
  /**
   * 设置metaProtocol
   * @param metaProtocol
   */
  setMetaProtocol(metaProtocol: MetaProtocol) {
    this.metaProtocol = metaProtocol;
  }
  /**
   * 获取点赞数据
   */
  async getTxPayLike(): Promise<Array<any>> {
    let likes: Array<any> = [];
    if (!this.metaData.txId) return [];
    const r: any = await getPayLikes(this.metaData.txId).catch((err) => {
      console.log("getTxPayLike 获取点赞数据出错");
      return false;
    });
    if (r) {
      likes = r.data;
      // likes = likes.filter((like) => {
      //   return like.metaId == config.metaIdTag;
      // });
      this.likes = likes;
    }
    return this.likes;
  }
  /**
   * 获取评论数据
   */
  async getTxComments(): Promise<Array<any>> {
    let comments: Array<any> = [];
    if (!this.metaData.txId) return comments;
    const r: any = await getPayComment(this.metaData.txId).catch((err) => {
      console.log("getTxComments 获取评论数据出错");
      return false;
    });
    if (r) {
      comments = r.data;
      // comments = comments.filter((comment) => {
      //   return comment.metaId == config.metaIdTag;
      // });
      this.comments = comments;
    }
    return this.comments;
  }
  /**
   * 获取当前metaData用户信息
   */
  async getUserInfo() {
    const r: any = await getShowAccount(this.metaData.rootTxId).catch((err) => {
      console.log("");
    });
    this.userInfo = r;
    return r;
  }
  /**
   * 生成点赞
   * @param param
   * @param txId
   */
  createLike(param: SendMetaDataTxParams, txId: string) {
    const payLike = {
      isNew: true,
      parentNodeName: "PayLike",
      timestamp: Date.now(),
      txId: txId,
      data: JSON.parse(param.data),
      rootTxId: this.metaProtocol?.userInfo.metaId,
    };
    getTxData(txId).then((r: any) => {
      if (r) {
        const txData = r.data[0];
        const idx = this.likes.findIndex((item) => {
          return item.txId == txData.txId;
        });
        if (idx > -1) {
          this.vue.$delete(this.likes, idx);
          this.likes.push(txData);
        }
      }
    });
    return payLike;
  }
  /**
   * 点赞操作
   */
  payLike() {
    return new Promise(async (resolve, reject) => {
      if (!this.userInfo) {
        await this.getUserInfo();
      }
      /**
       * 初始值添加项目付款out
       */
      const payTo: Array<PayToItemInterface> = [
        {
          address: config.projectAddress,
          amount: config.payLikeFee,
        },
      ];

      if (this.userInfo?.address) {
        payTo.push({
          address: this.userInfo?.address || "",
          amount: config.payLikeAmount,
        });
      } else {
        return reject("no user Address for pay");
      }
      const data: PayLikeProtocolInterface = {
        createTime: Date.now(),
        isLike: "1",
        likeTo: this.txId,
        pay: `${config.payLikeAmount}`,
        payTo: this.userInfo.address || "",
      };
      const likeParam = (await this.metaProtocol?.payLike(
        data,
        payTo
      )) as SendMetaDataTxParams;
      console.log("likeParam:", likeParam);
      const r: any = await this.metaSdk?.sendMataData(likeParam);
      if (r) {
        const payLike: any = this.createLike(likeParam, r.txId);
        this.likes.push(payLike);
        return resolve(r.txId);
      } else {
        //没返回数据
        //TODO:上层处理
      }
    });
  }
  /**
   * 评论
   * @param commentConent
   */
  payComment(commentConent: string) {
    return new Promise(async (resolve, reject) => {
      if (!this.userInfo) {
        await this.getUserInfo();
      }
      /**
       * 初始值添加项目付款out
       */
      const payTo: Array<PayToItemInterface> = [
        {
          address: config.projectAddress,
          amount: config.payLikeFee,
        },
      ];

      if (this.userInfo?.address) {
        payTo.push({
          address: this.userInfo?.address || "",
          amount: config.payLikeAmount,
        });
      } else {
        return reject("no user Address for pay");
      }
      const data: PayCommentProtocolInterface = {
        createTime: Date.now(),
        content: commentConent,
        contentType: "text/plain",
        pay: `${config.payCommentAmount}`,
        commentTo: this.txId,
        payTo: this.userInfo.address,
      };
      const payCommentParams: SendMetaDataTxParams = (await this.metaProtocol?.payComment(
        data,
        payTo
      )) as SendMetaDataTxParams;
      console.log("payCommentParams:", payCommentParams);
      const r: any = await this.metaSdk?.sendMataData(payCommentParams);
      if (r) {
        const payComment: any = this.createComment(payCommentParams, r.txId);
        this.comments.push(payComment);
        return resolve(r.txId);
      } else {
        //没返回数据
        //TODO:上层处理
      }
    });
  }
  /**
   * 生成评论data
   * @param param
   * @param txId
   */
  createComment(param: SendMetaDataTxParams, txId: string) {
    const payComment = {
      isNew: true,
      parentNodeName: "PayLike",
      timestamp: Date.now(),
      txId: txId,
      data: JSON.parse(param.data),
      rootTxId: this.metaProtocol?.userInfo.metaId,
    };
    getTxData(txId).then((r: any) => {
      if (r) {
        const txData = r.data[0];
        const idx = this.comments.findIndex((item) => {
          return item.txId == txData.txId;
        });
        if (idx > -1) {
          this.vue.$delete(this.comments, idx);
          this.comments.push(txData);
        }
      }
    });
    return payComment;
  }
  /**
   * 支付文章 返回交易后的txid
   */
  metaAccessPay(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const metaAccessServiceConfigData: any = await this.getMetaAccessServiceConfig();
      const metaAccess: any = await this.getMetaAccess();
      const payTo: Array<any> = [];
      if (this.currentUserMeataAccessPay) {
        return resolve(this.currentUserMeataAccessPay.txId);
      } else {
        const metaAccessPayDataArray: Array<any> = await this.getMetaAccessPayMetaData(
          this.currentUser?.metaId
        );
        if (metaAccessPayDataArray.length == 1) {
          this.currentUserMeataAccessPay = metaAccessPayDataArray[0];
          return resolve(this.currentUserMeataAccessPay.txId);
        }
      }
      if (!metaAccessServiceConfigData) {
        console.log("metaAccessPay op 些文章没有交易服务商");
        return reject("此文章没有交易服务商");
      } else {
        const serviceAmont = metaAccessServiceConfigData.data.fee;
        if (serviceAmont > 0) {
          const serviceUserInfo: any = await getShowAccount(
            metaAccessServiceConfigData.rootTxId
          );
          if (serviceUserInfo) {
            payTo.push({
              address: serviceUserInfo.address,
              amount: Math.floor(serviceAmont),
            });
          }
        }
      }
      if (metaAccess) {
        payTo.push({
          address: this.userInfo?.address,
          amount: Math.floor(metaAccess.data.amount),
        });
      }

      const params = {
        payTo: payTo,
        data: JSON.stringify({
          metaAccessService: this.metaData.data.metaAccessService, // 服务商metaid
          metaAccessServiceConfigTx: metaAccessServiceConfigData.txId, // 服务商 config-metanetid TODO:不在文章协议里带上？
          metaAccessServiceConfigMetanetID: this.metaData.data
            .metaAccessServiceConfigMetanetID, // 服务商 config-metanetid
          metaAccessMetanetID: metaAccess.metanetId, // 购买 metanetid
          metaAccessTx: metaAccess.txId, // 购买 tx
          createTime: Date.now(),
        }),
      };

      const op: any = await this.metaProtocol?.createMetaAccessPay(params);
      const r: any = await this.metaSdk
        ?.sendMataData(op)
        .catch((err) => reject(err));
      if (r) {
        resolve(r.txId);
      }
    });
  }
  /**
   * 获取当前文章服务商
   */
  getMetaAccessServiceConfig() {
    return new Promise(async (resolve, reject) => {
      if (!this.metaAccessServiceConfigMetaMetaData) {
        const params = {
          find: {
            "data.metaID": {
              $ne: 1,
            },
            metanetId: this.metaData.data.metaAccessServiceConfigMetanetID,
            parentNodeName: "MetaAccessServiceConfig",
          },
        };
        const r: any = await getQueryFindMetaData(params);
        console.log("getMetaAccessServiceConfig:", r);

        if (r && r.total == 1) {
          this.metaAccessServiceConfigMetaMetaData = r.data[0];
        }
        resolve(this.metaAccessServiceConfigMetaMetaData);
      } else {
        resolve(this.metaAccessServiceConfigMetaMetaData);
      }
    });
  }
  /**
   * 请求获取文章解密内容
   * @param payTx
   */
  toDecryptArticleMessage(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let payTx = "";
      const isPayed: boolean = await this.isPayedArticle(); //获取是否支付过的文章
      if (!isPayed) {
        //支付交易
        const r: any = await this.metaAccessPay().catch((err) => {
          reject("查找文章支付交易出错");
          return false;
        });
        if (r) {
          payTx = r;
        }
      } else {
        //TODO:不正确用法this.metaAccessPayData
        payTx = this.currentUserMeataAccessPay.txId;
      }
      if (!payTx) {
        console.log("toDecryptArticleMessage payTx:", payTx);
        return reject("没有交易 payTx");
      }
      setTimeout(
        async () => {
          const metaAccessServiceConfigData: any = await this.getMetaAccessServiceConfig();
          const url = metaAccessServiceConfigData.data.url;
          const metaAccess: any = await this.getMetaAccess();
          const params = {
            serverPublicKey: this.metaData.data.serverPublicKey,
            serverCode: this.metaData.data.serverCode, // 6字节
            metaAccessMetanetID: metaAccess.metanetId,
            metaAccessPayTx: payTx,
          };
          const r: any = await getArticleDecryptMessage(url, params).catch(
            (err) => {
              console.log("getArticleDecryptMessage异常");
              reject("getArticleDecryptMessage异常");
              return false;
            }
          );
          if (r) {
            resolve(r);
          }
        },
        isPayed ? 0 : 1000
      );
    });
  }
  getMetaAccessPayMetaData(userTxId?: any): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      const metaAccess: any = await this.getMetaAccess();
      if (!metaAccess) {
        return resolve([]);
      }
      const metaAccessServiceConfigData: any = await this.getMetaAccessServiceConfig(); //TODO:是不需要加服务商条件
      const params: any = {
        find: {
          "data.metaAccessServiceConfigMetanetID": this
            .metaAccessServiceConfigMetaMetaData.metanetId,
          "data.metaAccessServiceConfigTx": this
            .metaAccessServiceConfigMetaMetaData.txId,
          "data.metaAccessService": this.metaAccessServiceConfigMetaMetaData
            .data.metaID,
          "data.metaAccessMetanetID": metaAccess.metanetId,
          "data.metaAccessTx": metaAccess.txId,
          parentNodeName: "MetaAccessPay",
        },
      };
      if (userTxId) {
        params.find.rootTxId = userTxId;
      }
      const r: any = await getQueryFindMetaData(params);
      if (r) {
        resolve(r.data);
      } else {
        resolve([]);
      }
    });
  }
  /**
   * 获取当前文章所有交易信息
   */
  getMetaAccessPay() {
    return new Promise(async (resolve, reject) => {
      if (!this.metaAccessPayData) {
        this.metaAccessPayData = await this.getMetaAccessPayMetaData();
        resolve(this.metaAccessPayData);
      } else {
        resolve(this.metaAccessPayData);
      }
    });
  }
  /**
   * 是否支付文章
   */
  isPayedArticle(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.currentUser) {
        const r: Array<any> = await this.getMetaAccessPayMetaData(
          this.currentUser.metaId
        );
        if (r.length > 0) {
          this.currentUserMeataAccessPay = r[0];
          resolve(true);
        } else {
          resolve(false);
        }
        resolve(r.length > 0);
      } else {
        const timer: any = setTimeout(() => {
          if (this.currentUser) {
            resolve(true);
          } else {
            resolve(false);
          }
          clearTimeout(timer);
        }, 30000);
        const watchUser: any = this.vue.$store.watch(
          (state, getters) => {
            return state.app.currentUser;
          },
          (val: UserInfoTypes, oldVal: any) => {
            this.currentUser = val;
            this.getMetaAccessPayMetaData(val.metaId).then((r: Array<any>) => {
              resolve(r.length > 0);
            });
            clearTimeout(timer);
            watchUser();
          }
        );
      }
    });
  }
  /**
   * 获取当前文章的metaAccess
   */
  getMetaAccess() {
    return new Promise(async (resolve, reject) => {
      if (!this.metaAccess) {
        const params = {
          find: {
            parentNodeName: "MetaAccess",
            "data.metaAccessContentMetanetID": this.metaData.metanetId,
            isNew: true,
          },
        };
        const r: any = await getQueryFindMetaData(params);
        if (r && r.total == 1) {
          this.metaAccess = r.data[0];
        }
        resolve(this.metaAccess);
      } else {
        resolve(this.metaAccess);
      }
    });
  }
  /**
   * 获取文章收入
   */
  async getArtIncome() {
    await this.getMetaAccessPay();
    await this.getMetaAccess();
    return {
      allIncome: this.metaAccess
        ? (this.metaAccess.data.amount * this.metaAccessPayData.length) /
          Math.pow(10, 8)
        : 0 * this.metaAccessPayData.length,
      metaAccessPayData: this.metaAccessPayData,
      metaAcces: this.metaAccess,
    };
  }
  /**
   * 简单订阅当前文章所有人
   */
  async simpleSubscribe() {
    return new Promise(async (resolve, reject) => {
      /**
       * 初始值添加项目付款out
       */
      const payTo: Array<PayToItemInterface> = [
        {
          address: config.projectAddress,
          amount: config.simpleSubscribeFee,
        },
      ];

      if (this.userInfo?.address) {
        payTo.push({
          address: this.userInfo?.address || "",
          amount: config.simpleSubscribeAmount,
        });
      } else {
        return reject("no user Address for pay");
      }
      const data = {
        createTime: Date.now(),
        MetaID: this.metaData.rootTxId,
        pay: `${config.simpleSubscribeAmount}`,
        payTo: this.userInfo.address,
      };
      const params: any = await this.metaProtocol?.createSimpleSubscribe(
        payTo,
        data
      );
      const r = await this.metaSdk?.sendMataData(params).catch((err: any) => {
        console.log("simpleSubscribe error:", err);
        reject(err);
        return false;
      });
      if (r) {
        resolve(r);
      }
    });
  }
  /**
   *
   */
  async simpleUnSubscribe() {
    return new Promise(async (resolve, reject) => {
      const data = {
        createTime: Date.now(),
        MetaID: this.metaData.rootTxId,
      };
      const params: any = await this.metaProtocol?.createSimpleCancleSubscribe(
        data
      );

      const r = await this.metaSdk?.sendMataData(params).catch((err: any) => {
        console.log("simpleUnSubscribe error:", err);
        reject(err);
        return false;
      });
      if (r) {
        resolve(r);
      }
    });
  }
  isUnSubScribeMe(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const result: any = await getQueryFindMetaData({
        find: {
          $or: [
            {
              parentNodeName: "SimpleCancleSubscribe",
            },
            {
              parentNodeName: "SimpleSubscribe",
            },
          ],
          "data.MetaID": this.userInfo?.metaId,
          rootTxId: this.currentUser?.metaId,
        },
      }).catch((err: any) => {
        console.log("isSubScribeMe", err);
        return false;
      });
      if (result) {
        this.simpleSubData = result.data;
        const subData = this.simpleSubData.filter((item: any) => {
          return item.parentNodeName !== "SimpleCancleSubscribe";
        });
        const unSubData = this.simpleSubData.filter((item: any) => {
          return item.parentNodeName == "SimpleCancleSubscribe";
        });
        resolve(unSubData.length >= subData.length);
      } else {
        resolve(false);
      }
    });
  }
}
