





























import { Component, Prop, Vue } from "vue-property-decorator";
import MyCountTo from "@/components/common/myCountTo.vue";
const markIcon = require("@/assets/image/icon_mark@2x.png");
@Component({
  name: "ItemBlock",
  components: {
    MyCountTo,
  },
})
export default class ItemBlock extends Vue {
  markIcon: any = markIcon;
  @Prop({
    default: "0",
  })
  value!: any;
  @Prop({
    type: String,
  })
  unit!: string;
  @Prop({
    type: String,
    required: true,
  })
  label!: string;
  @Prop({
    type: String,
  })
  blockUnit!: string;
  @Prop({
    type: String,
  })
  labelTip!: string;
  @Prop({
    type: Number,
    default: 0,
  })
  decimals!: string;
}
