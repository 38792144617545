import config from "@/config";
import { formatDate } from "../utils/date";
const formatDateStr = (date: any, format: any = "hh:mm") => {
  return formatDate(date, format);
};

const formatTxid = (txid: string,len:number=5) => {
  return txid.slice(0, len);
};
const metaFileUrl = (metaFile: string) => {
  return `${config.metaFileServiceUrl}/metafile/${metaFile.split("//")[1]}`;
};

const toThousands = (number: number) => {
  if (!number) {
    return "0"
  }
  let num: string = (number || 0).toString(), result = '';
  let num_split: Array<string> = num.split('.')
  num = num_split[0]
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
  if (num) { result = num + result; }
  if (num_split.length > 1) {
   return result +'.' +num_split[1] 
  } else {
    return result;
  }
}

export { formatDateStr, formatTxid, metaFileUrl ,toThousands};
