





















import { MetaBlockUserInfoPath } from "@/store/modules/metaBlockUserInfo";
import _ from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "HeaderTabs",
})
export default class HeaderTabs extends Vue {
  @Getter("app/tabList") tabList!: any;
  @Getter("isLogined") isLogined!: boolean;
  @Getter("metaBlockUserInfo/isScroll") isScroll!: boolean;
  tabsValue: string = "";
  scrollList: Array<any> = [];
  tabsList: Array<any> = [
    // {
    //   label: "首页",
    //   key: "home",
    // },
    {
      label: "我的MetaID",
      key: "my",
    },
    {
      label: "MetaBlock",
      key: "all",
    },
    {
      label: "MetaID交易",
      key: "new",
    },
  ];
  debounceCurrentTab: Function = _.debounce(() => {
    console.log("throttleGetCurrentTab now:", Date.now());
    this.getCurrentTab();
  }, 200);
  tabChange(e: any) {
    // this.$store.dispatch(MetaBlockUserInfoPath.updateIsScroll, false);
    // document.body.onscroll = () => {
    //   console.log("document.body.onscroll");
    //   if (!this.isScroll) {
    //     setTimeout(() => {
    //       this.$store.dispatch(MetaBlockUserInfoPath.updateIsScroll, true);
    //       document.body.onscroll = null;
    //     }, 500);
    //   }
    // };
    if (this.$route.name != "Home") {
      this.$router.push({
        name: "Home",
      });
      this.$nextTick(() => {
        this.$vuetify.goTo(document.getElementById(e.key) as HTMLElement);
      });
    } else {
      this.$vuetify.goTo(document.getElementById(e.key) as HTMLElement);
    }
  }
  getCurrentTab() {
    if (this.scrollList.length == 0) {
      return;
    }
    let minItem = this.scrollList[0];
    this.scrollList.forEach((item: any) => {
      if (item.target.offsetTop <= minItem.target.offsetTop) {
        minItem = item;
      }
    });
    console.log("min:", this.scrollList, minItem, Date.now());
    // this.tabsValue = minItem.target.id == "my" ? "home" : minItem.target.id;
    this.tabsValue = minItem.target.id;
    // }
  }
  @Watch("tabList")
  watchTabList(val: any) {
    // this.debounceWatchTabList(val);
    console.log("watchTabList:", val, Date.now());
    const findIdx = this.scrollList.findIndex((item: any, idx: number) => {
      return item.target.id == val.target.id;
    });
    if (findIdx >= 0) {
      this.scrollList[findIdx] = val;
    } else {
      this.scrollList.push(val);
    }
    // console.log("this.scrollList after filter:", this.scrollList, findIdx);
    this.scrollList = this.scrollList.filter((item: any) => {
      return item.isIntersecting;
    });
    this.debounceCurrentTab();
    // this.getCurrentTab();
  }
  destroyed() {
    document.body.onscroll = null;
  }
}
