export const state = () => ({
  title: "",
  name: "",
  avatarTxId: "",
  draftNum: -1,
  more: true,
});

export const getters = {
  title: (state: any) => {
    return state.title;
  },
  name: (state: any) => {
    return state.name;
  },
  avatarTxId: (state: any) => {
    return state.avatarTxId;
  },
  draftNum: (state: any) => {
    return state.draftNum;
  },
  more: (state: any) => {
    return state.more;
  },
};

export const mutations = {
  updateTitle: (state: any, payload: any) => {
    state.name = "";
    state.avatarTxId = "";
    state.title = payload;
  },
  updateName: (state: any, payload: any) => {
    state.title = "";
    state.draftNum = -1;
    state.more = true;
    state.name = payload;
  },
  updateAvatarTxId: (state: any, payload: any) => {
    state.title = "";
    state.draftNum = -1;
    state.more = true;
    state.avatarTxId = payload;
  },
  updateDraftNum: (state: any, payload: any) => {
    state.name;
    state.more = false;
    state.draftNum = payload;
  },
  updateMore: (state: any, payload: any) => {
    if (payload) {
      state.draftNum = -1;
    }
    state.more = payload;
  },
  updateOnlyBack: (state: any) => {
    state.title = "";
    state.name = "";
    state.avatarTxId = "";
    state.draftNum = -1;
    state.more = false;
  },
};
export const ShareHeaderPath = {
  updateTitle: "shareHeader/updateTitle",
  updateName: "shareHeader/updateName",
  updateAvatarTxId: "shareHeader/updateAvatarTxId",
  updateDraftNum: "shareHeader/updateDraftNum",
  updateMore: "shareHeader/updateMore",
  updateOnlyBack: "shareHeader/updateOnlyBack",
};
export const actions = {
  updateTitle({ commit }: { commit: Function }, payload: any) {
    commit("updateTitle", payload);
  },
  updateName({ commit }: { commit: Function }, payload: any) {
    commit("updateName", payload);
  },
  updateAvatarTxId({ commit }: { commit: Function }, payload: any) {
    commit("updateAvatarTxId", payload);
  },
  updateDraftNum({ commit }: { commit: Function }, payload: any) {
    commit("updateDraftNum", payload);
  },
  updateMore({ commit }: { commit: Function }, payload: any) {
    commit("updateMore", payload);
  },
  updateOnlyBack({ commit }: { commit: Function }) {
    commit("updateOnlyBack");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
