















































































































































































































































































import { goAuth } from "@/api/api_helper";
import { getCoinInfoWithAddress } from "@/api";
import { UserInfoTypes } from "@/metaid-types";
import { UserMetaBlockInfo } from "@/store/modules/metaBlockUserInfo";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import MyCountTo from "@/components/common/myCountTo.vue";
import {mapGetters} from "vuex";
const mcIcon = require("@/assets/image/icon_mc@2x.png");
const dotIcon = require("@/assets/image/dot@2x.png");
const markIcon = require("@/assets/image/icon_mark@2x.png");
@Component({
  name: "MetaUserData",
  components: {
    MyCountTo,
  },
})
export default class MetaUserData extends Vue {
  @Getter("isLogined") isLogined!: boolean;
  @Getter("app/userLogining") userLogining!: boolean;
  @Getter("app/currentUser") currentUser!: UserInfoTypes;
  @Getter("metaBlockUserInfo/userMetaBlockInfo")
  userBlockInfo!: UserMetaBlockInfo;
  dotIcon: any = dotIcon;
  meIcon: any = mcIcon;
  markIcon: any = markIcon;
  dotNum: any = "";
  mcBlance?: number = 0;
  sendedDotNum: any = "";
  isEng: boolean = this.$i18n.locale == "en";
  loginHandle() {
    goAuth();
  }
  @Watch("$i18n.locale")
  lanCurrent(val: any) {
    this.isEng = val == "en";
  }
  @Watch("currentUser")
  watchCurrentUser(currentUser: any) {
    if (currentUser) {
      this.getUserBlance();
      this.$store.dispatch("metaBlockUserInfo/getMetaUserInfo", {
        metaid: currentUser.metaId,
      });
    }
  }
  @Watch("userBlockInfo.metablockcount")
  watchMetablockmycount(val: any) {
    this.$store.dispatch("metaIdDataView/updateCurrentblocktx", val);
  }
  get myBlockPercent() {
    console.log('Info',this.userBlockInfo)
    if (!this.userBlockInfo.userSize) {
      return "0";
    }
    const result = this.userBlockInfo.userSize / this.userBlockInfo.totalSize;
    return (Number(result.toFixed(5)) * 100).toFixed(3);
  }
  get myAboutBlockPercent() {
    return (Number(this.myBlockPercent) * Math.pow(10, 5) * 5) / 100;
  }
  get sendedCoin() {
    // this.userBlockInfo.metacoin = 23947932874923234
    if (!this.userBlockInfo.sendedMetacoin) {
      return 0;
    } else {
      const r = this.userBlockInfo.sendedMetacoin / Math.pow(10, 8);
      const num_str = r.toString();
      const num_arr = num_str.split(".");
      if (num_arr.length > 1) {
        this.sendedDotNum = Number(num_arr[1]);
      }
      return Number(num_arr[0]);
    }
  }
  getUserBlance() {
    if (this.currentUser) {
      getCoinInfoWithAddress(this.currentUser.address as string).then(
        (res: any) => {
          (res && res.length) && res.forEach((element: any) => {
            if (element.codehash === "777e4dd291059c9f7a0fd563f7204576dcceb791" && element.genesis === "54256eb1b9c815a37c4af1b82791ec6bdf5b3fa3" && element.sensibleId === "3e04f81d7fa7d4d606c3c4c8e8d3a8dcf58b5808740d40a445f3884e126bc7fd00000000") {
              const r = (element.balance + element.pendingBalance) / Math.pow(10, 8);
              const num_str = r.toString();
              const num_arr = num_str.split(".");
              if (num_arr.length > 1) {
                this.dotNum = Number(num_arr[1]);
              }
              this.mcBlance = Number(num_arr[0]);
            }
          });
        }
      );
    }
  }
  toMcDetail() {
    this.$router.push({
      name: "mcRecord",
    });
  }
  mounted() {
    this.getUserBlance();
  }
}
