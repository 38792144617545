











































































































































import config from "@/config";
import { MetaBlockTablePath, VtableHeader } from "@/store/modules/metaBlock";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Paginate from "vuejs-paginate";
const arrowIcon = require("@/assets/image/list_icon_ins@2x.png");
@Component({
  name: "MetaBlockTable",
  filters: {
    protocolSplit(val: string, tag: string = "-") {
      return val.split(tag)[0];
    },
  },
  components: {
    Paginate,
  },
})
export default class MetaBlockTable extends Vue {
  @Getter("metaBlock/tableHeader") tableHeader!: Array<VtableHeader>;
  @Getter("metaBlock/tableData") tableData!: Array<any>;
  // @Getter("metaBlock/pages") pages!: number;
  @Getter("metaBlock/currentPage") currentPage!: number;
  @Getter("blockTableMaxPages") blockTableMaxPages!: number;
  page: number = 1;
  pageSize: number = config.tablePage;
  more: boolean = false;
  arrowIcon: any = arrowIcon;

  toWatchOnChain(tx: string) {
    window.open(`https://whatsonchain.com/tx/${tx}`, "_blank");
  }
  toProtocol() {
    window.open(`https://www.metaprotocols.com/`, "_blank");
  }
  previousPage() {
    console.log("prev currentPage:", this.page);
    this.getTableData();
  }
  nextPage() {
    this.getTableData();
    console.log("next currentPage:", this.page);
  }
  getTableData() {
    this.$store.dispatch(MetaBlockTablePath.getBlockTxList, {
      Page: this.page.toString(),
      PageSize: this.pageSize.toString(),
    });
  }
  inputChange() {
    this.getTableData();
  }
  mounted() {
    this.page = this.currentPage;
    this.getTableData();
  }
}
