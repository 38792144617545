import { getMetaBlockWithUserV2 } from "@/api/api_helper"

export interface UserMetaBlockInfo {
    metablockmycount:number,
    metablockcount:number,
    metacoin: number,
    totalFee: number,
    userFee:number
    sendedMetacoin:number,
    totalSize:number,
    userSize:number
}
export interface MetaBlockUserInfoState{
    userMetaBlockInfo: UserMetaBlockInfo,
    isScroll:boolean
}
export const state = () => ({
    userMetaBlockInfo: {
        metablockmycount:0,
        metablockcount:0,
        metacoin: 0,
    },
    isScroll:true
})

export const getters = {
    userMetaBlockInfo(state: MetaBlockUserInfoState) {
        return state.userMetaBlockInfo
    },
    isScroll(state: MetaBlockUserInfoState) {
        return state.isScroll
    }
}

export const mutations = {
    updateUserMetaBlockInfo(state: MetaBlockUserInfoState, payload: any) {
        state.userMetaBlockInfo = payload
    },
    updateIsScroll(state: MetaBlockUserInfoState, payload: any) {
      state.isScroll = payload  
    }
}
export const  MetaBlockUserInfoPath = {
    getMetaUserInfo:"metaBlockUserInfo/getMetaUserInfo",
    updateIsScroll:"metaBlockUserInfo/updateIsScroll",
}
export const actions = {
    getMetaUserInfo({ commit }: { commit: Function }, payload: any) {
        getMetaBlockWithUserV2(payload).then((result: any) => {
            commit('updateUserMetaBlockInfo',result)
        }).catch((err:any) => {
            console.log("getMetaBlockWithUserV2 err",err)
        });
    },
    updateIsScroll({ commit }: { commit: Function }, payload: any) {
      commit('updateIsScroll',payload)  
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
