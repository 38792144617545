import { SendMetaDataTxParams } from "@/metaid-types";
import MetaIdJs, { ProtocolOptions } from "metaidjs";

export default class MetaSdk {
  isApp: boolean;
  accessToken: string;
  metaidJs: MetaIdJs;
  constructor(isApp: boolean, accessToken: string, metaidJs: MetaIdJs) {
    this.isApp = isApp;
    this.accessToken = accessToken;
    this.metaidJs = metaidJs;
  }
  private getRandomId() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  ecdhEncryptData(data: any) {
    return new Promise((resolve, reject) => {
      this.metaidJs.ecdhEncryptData({
        accessToken: this.accessToken,
        callback: function(r: any) {
          if (r.code == 200) {
            resolve(r.data.data);
          } else {
            reject(r.data.message);
          }
        },
        data: data,
      });
    });
  }
  ecdhDecryptData(data: any) {
    return new Promise((resolve, reject) => {
      this.metaidJs.ecdhDecryptData({
        accessToken: this.accessToken,
        callback: function(r: any) {
          if (r.code == 200) {
            resolve(r.data.data);
          } else {
            reject(r.data.message);
          }
        },
        data: data,
      });
    });
  }
  sendMataData(params: SendMetaDataTxParams) {
    return new Promise((resolve, reject) => {
      if (!window.handlers) {
        window.handlers = {};
      }
      const successHandle: Function = (r: any) => {
        if (r.code == 200) {
          console.log("sendMataData params:", params);
          console.log("sendMataData result:", r);
          return resolve(r.data);
        } else {
          if (r.code == 205) {
            resolve(r.data);
          } else {
            return reject(r);
          }
        }
      };
      const randomId = this.getRandomId();
      const handlerKey = "sendMataData" + randomId;
      if (this.isApp) {
        window.handlers[handlerKey] = successHandle;
        window.appMetaIdJs.sendMetaDataTx(
          params.accessToken || this.accessToken,
          JSON.stringify(params),
          ["handlers", handlerKey].join(".")
        );
      } else {
        params.callback = successHandle;
        params.onCancel = function(err: any) {
          reject();
        };
        this.metaidJs.sendMetaDataTx(params);
      }
    });
  }
}
