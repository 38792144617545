import { appMetaIdJs, UserInfoTypes } from "@/metaid-types";
import MetaProtocol from "@/utils/classModule/metaProtocol";
import MetaSdk from "@/utils/classModule/metaSdk";
import { removeLocal, setLocal } from "@/utils/storage";
import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface appState {
  code: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  appAccessToken: string | undefined;
  isMobile: boolean;
  appMetaIdJs: appMetaIdJs;
  isLoadMetaIdJs: boolean;
  currentUser?: UserInfoTypes | undefined;
  metaSdk?: MetaSdk;
  metaProtocol?: MetaProtocol;
  userLogining?: boolean;
  tabList?: object;
  autoLogining?: boolean;
}
export const state = (): appState => ({
  code: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  appAccessToken: undefined,
  // isApp: false,
  isMobile: false,
  appMetaIdJs: window.appMetaIdJs,
  isLoadMetaIdJs: false,
  currentUser: undefined,
  metaSdk: undefined,
  metaProtocol: undefined,
  userLogining: false,
  tabList: { time: 0 },
  autoLogining: false,
});

export const getters = {
  isMobile: (state: appState) => {
    return state.isMobile;
  },
  code: (state: appState) => {
    return state.code;
  },
  isLogined: (state: appState) => {
    return !!state.accessToken || !!state.appAccessToken;
  },
  isApp: (state: appState) => {
    return state.appMetaIdJs != undefined;
  },
  currentUser: (state: appState) => {
    return state.currentUser;
  },
  userLogining: (state: appState) => {
    return state.userLogining;
  },
  tabList: (state: appState) => {
    return state.tabList;
  },
};

export const mutations = {
  updateIsMobile: (state: appState, isMobile: boolean) => {
    state.isMobile = isMobile;
  },
  updateAccessToken: (state: appState, accessToken: string) => {
    setLocal("accessToken", accessToken);
    state.accessToken = accessToken;
  },
  updateRefreshToken: (state: appState, refreshToken: string) => {
    setLocal("refreshToken", refreshToken);
    state.refreshToken = refreshToken;
  },
  updateCode: (state: appState, code: string) => {
    state.code = code;
  },
  loginOut: (state: appState) => {
    state.accessToken = undefined;
    state.appAccessToken = undefined;
    state.currentUser = undefined;
    removeLocal("accessToken");
    removeLocal("refreshToken");
    removeLocal("appAccessToken");
  },
  updateAppAccessToken: (state: appState, appAccessToken: string) => {
    setLocal("appAccessToken", appAccessToken);
    state.appAccessToken = appAccessToken;
  },
  updateMetaIdJsStatus: (state: appState, isLoadMetaIdJs: boolean) => {
    state.isLoadMetaIdJs = isLoadMetaIdJs;
  },
  updateCurrentUser: (state: appState, userInfo: any) => {
    state.currentUser = userInfo;
  },
  updateMetaSdk: (state: appState, metaSdk: MetaSdk) => {
    state.metaSdk = metaSdk;
  },
  updateMetaProtocol: (state: appState, metaProtocol: MetaProtocol) => {
    state.metaProtocol = metaProtocol;
  },
  updateUserLogining: (state: appState, userLogining: boolean) => {
    state.userLogining = userLogining;
  },
  tabList: (state: appState, tabList: any) => {
    state.tabList = tabList;
  },
  updateAutoLogining: (state: appState, login: boolean) => {
    state.autoLogining = login;
  },
};

export const appDispath = {
  updateIsMobile: "app/updateIsMobile",
  updateAccessToken: "app/updateAccessToken",
  updateRefreshToken: "app/updateRefreshToken",
  updateCode: "app/updateCode",
  loginOut: "app/loginOut",
  updateAppAccessToken: "app/updateAppAccessToken",
  updateMetaIdJsStatus: "app/updateMetaIdJsStatus",
  updateCurrentUser: "app/updateCurrentUser",
  updateMetaSdk: "app/updateMetaSdk",
  updateMetaProtocol: "app/updateMetaProtocol",
  updateUserLogining: "app/updateUserLogining",
  updateTabList: "app/tabList",
  updateAutoLogining: "app/updateAutoLogining",
};

export const actions = {
  updateIsMobile({ commit }: { commit: Function }, val: boolean) {
    commit("updateIsMobile", val);
  },
  updateAccessToken({ commit }: { commit: Function }, val: any) {
    commit("updateAccessToken", val);
  },
  updateRefreshToken({ commit }: { commit: Function }, val: any) {
    commit("updateRefreshToken", val);
  },
  updateCode({ commit }: { commit: Function }, val: any) {
    commit("updateCode", val);
  },
  loginOut({ commit }: { commit: Function }) {
    console.log("退出登录");
    commit("loginOut");
  },
  updateAppAccessToken({ commit }: { commit: Function }, val: any) {
    commit("updateAppAccessToken", val);
  },
  updateMetaIdJsStatus({ commit }: { commit: Function }, val: boolean) {
    commit("updateMetaIdJsStatus", val);
  },
  updateCurrentUser({ commit }: { commit: Function }, val: any) {
    commit("updateCurrentUser", val);
  },
  updateMetaSdk({ commit }: { commit: Function }, val: any) {
    commit("updateMetaSdk", val);
  },
  updateMetaProtocol({ commit }: { commit: Function }, val: any) {
    commit("updateMetaProtocol", val);
  },
  updateUserLogining({ commit }: { commit: Function }, val: any) {
    commit("updateUserLogining", val);
  },
  tabList({ commit }: { commit: Function }, val: any) {
    commit("tabList", val);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
