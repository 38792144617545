
























import { getLocal, setLocal } from "@/utils/storage";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "LanSelect",
})
export default class LanSelect extends Vue {
  lan: string = this.$i18n.locale;
  lanList: Array<any> = [
    {
      label: "en",
      key: "en",
    },
    {
      label: "cn",
      key: "zhHans",
    },
  ];
  handleMenuClick(item: { label: string; key: string }) {
    this.lan = item.key;
    this.$i18n.locale = item.key;
    setLocal("lang", this.$i18n.locale);
  }
  mounted() {
    const lang = getLocal("lang");
    if (lang) {
      this.$i18n.locale = lang;
    }
  }
}
