import { getMetaCoinHistory, MetaCoinHistoryGetParams } from "@/api/api_helper";
import config from "@/config";
import { VtableHeader } from "./metaBlock";
import {McDetailState} from "@/store/modules/mcDetail";

export interface McBlockState {
  tableHeader: Array<VtableHeader>;
  tableData: Array<any>;
  tableAllData: Array<any>;
  currentPage: number;
  pages: number;
  hasmetablock?:number
}
export const state = (): McBlockState => ({
  currentPage: 1,
  pages: 10,
  tableHeader: [
    {
      text: "区块高度",
      value: "metablock",
      width: "100px",
      class: "text-center text-truncate",
      lang_key: "height_mc_block",
      type: 'fee'
    },
    {
      text: "我上链的体积",
      value: "userId",
      width: "200px",
      changeValue: "userId",
      class: "text-center text-truncate",
      lang_key: "my_mc_onchain_fee",
      lang_temple_key: "my_mc_onchain_Expenses"
    },
    {
      text: "总上链大小/我的占比",
      value: "time",
      width: "200px",
      changeValue: "time",
      class: "text-center text-truncate",
      lang_key: "all_fee_percent",
      lang_temple_key: "all_Expenses_percent"
    },
    {
      text: "获得多少MetaCoin",
      value: "protocol",
      width: "200px",
      class: "text-center text-truncate",
      lang_key: "get_block_mc_amount",
    },
  ],
  tableData:
    process.env.VUE_APP_TYPE == "prod"
      ? []
      : [
          {
            block: 0,
            metaid:
              "9800979f36f3aa2765df97fd777e1ba77586f4662a34c088529fcd83023a5699",
            create_time: 1616738322724,
            protocol: "PayFollow-02ba7b3b5a28",
            tx:
              "b75f997f58fd0940416eb1958dc8e930d86a2940e01ca99b51e1f912df0aad8a",
          },
          {
            block: 0,
            metaid:
              "6da6478b0c6e7aa95b0984934a660a6337708ffebb526d8471209cb4cb42188c",
            create_time: 1616738302536,
            protocol: "SimpleArticleDonate-036ad2903989",
            tx:
              "5e9213a25c01c280e7cc2929da536380460357d47e395bd9603e1a2e84a90066",
          },
          {
            block: 0,
            metaid:
              "7644ae88283ec3a176db3ff23dced60418e2bd8a55ae8425fc8e2061469a63ae",
            create_time: 1616738296039,
            protocol: "ShowMsg-0347092f8f02",
            tx:
              "2dd96a5b4a2c4db34f38fef95e281a94c70fa9afb8375f02e8cc3fa4998c465d",
          },
          {
            block: 0,
            metaid:
              "8618085c1a4db137b052237b7f052d1fdb14ac55599194016471833eb0410784",
            create_time: 1616738287954,
            protocol: "ShowMsg-02ae9878b19d",
            tx:
              "c95a52e64ce319d99f07d60cb72e8c09ecd57e2f8640fdfc4a811255bc1134a6",
          },
          {
            block: 0,
            metaid:
              "ece4ee8945fecd67c49eaab633c8bf36926692bbdb80b60db5a4063ee7b561eb",
            create_time: 1616738286001,
            protocol: "PayLike-0210ee6de6ee",
            tx:
              "55745a2865e6af4a315abe5b6a8f2b6414a40c6378680f6fbd31ab19a19d70e9",
          },
          {
            block: 0,
            metaid:
              "02d6faebb615e70f2988c297052c4d2a04016cb504872d6efb3a5f26ecba59d7",
            create_time: 1616738281446,
            protocol: "SimpleMicroblog-0321efd654d",
            tx:
              "3cd623deea69ca5c43f949d439745cb88edc5966a9ee0652073b10c1e69fecb4",
          },
          {
            block: 0,
            metaid:
              "02d6faebb615e70f2988c297052c4d2a04016cb504872d6efb3a5f26ecba59d7",
            create_time: 1616738281228,
            protocol: "16167382776506088.jpg",
            tx:
              "97b9b6d69abf6c54174cf68d8117bffe4625b6d64937d560dfd461ef21bf6f04",
          },
          {
            block: 0,
            metaid:
              "33f666a9851cc6cc8eda6c11006e1f1bb95c081a01cc130448ad7a64f974291f",
            create_time: 1616738277084,
            protocol: "SimpleRePost-032a1f384a4",
            tx:
              "d5a410b7c3ecfefe216fe9bf272a45556e8094636f4d6e097b8492d57e9f1092",
          },
          {
            block: 0,
            metaid:
              "f964f8cbf881f72a81046724d60df32d8925a77837aa57d434441c9aecd46261",
            create_time: 1616738270563,
            protocol: "SimpleRePost-02a99f02842",
            tx:
              "18de8d693d12413c3c4410e1abef55683e3519c87098ff3475c596b45c0c8b18",
          },
          {
            block: 0,
            metaid:
              "7644ae88283ec3a176db3ff23dced60418e2bd8a55ae8425fc8e2061469a63ae",
            create_time: 1616738269916,
            protocol: "ShowMsg-02736811a17c",
            tx:
              "a7050d36ca97f9cec9eced0466ee2d316e0acb8320ca62970e13751804712eb6",
          },
        ],
  tableAllData: [],
  hasmetablock:0
});

export const getters = {
  // getterValue: (state:McBlockState) => {
  //     return state.value
  // }
  tableHeader(state: McBlockState) {
    console.log('灿宝',state.tableHeader)
    return state.tableHeader;
  },
  tableData(state: McBlockState) {
    return state.tableData;
  },
};

export const mutations = {
  // updateValue: (state, payload) => {
  //     state.value = payload
  // }
  updateTableHeader(state:McBlockState ,payload: {type: number}) {
    let flag  = false
    if(payload.type === 1) {
      flag =  state.tableHeader.some(item=>item.type === 'fee');
    }else{
      flag =  state.tableHeader.some(item=>item.type === 'Expenses');
    }
    if(flag) {
      state.tableHeader = state.tableHeader.map(item=>{
        item.type && (item.type = item.type === 'fee'?'Expenses':'fee')
        if(item.lang_temple_key) {
          const lang_temple_key = item.lang_temple_key
          item.lang_temple_key = item.lang_key;
          item.lang_key = lang_temple_key;
        }
        return item
      })
    }
  },

  updateTableData(
    state: McBlockState,
    payload: { page: number; pageSize: number }
  ) {
    let list: Array<any> = [];
    let start = 0,
      end = payload.pageSize;
    console.log("updateTableData payload:", payload);
    if (payload.page >= 2) {
      start = (payload.page - 1) * payload.pageSize;
      end = payload.page * payload.pageSize;
      if (end > state.tableAllData.length) {
        end = state.tableAllData.length;
      }
    }
    list = state.tableAllData.slice(start, end);
    state.tableData = list;
  },
  updateTableAllData(state: McBlockState, payload: any) {
    state.tableAllData = payload;
  },
};

export const actions = {
  // updateActionValue({ commit }) {
  //     commit('updateValue', payload)
  // }
  commitTableHeader({commit}:{commit:Function},payload:{type:number}) {
    commit('updateTableHeader',payload)
  },

  getMetaCoinHistoryAction(
    { commit }: { commit: Function },
    params: MetaCoinHistoryGetParams
  ) {
    params.page = 1;
    getMetaCoinHistory(params).then((r: any) => {
      // console.log("getMetaCoinHistory数据：");
      // console.table(r);
      commit("updateTableAllData", r);
      commit("updateTableData", { page: 1, pageSize: config.tablePage });
    });
  },
};
export const mcBlockPath = {
  getMetaCoinHistoryAction: "mcBlock/getMetaCoinHistoryAction",
  updateTableData: "mcBlock/updateTableData",
  commitTableHeader: "mcBlock/commitTableHeader",
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
