import { getMetaIdInfoV2 } from "@/api/api_helper"

export interface MetaIdDataAllInfoInterface {
  metaidcount:number,
  txcount:number,
  amount:number,
  metablockcount:number,
  currentblocknew:number,
  currentblocktx:number,
  currentblockamount:number,
    metacoin: number,
}
export interface metaIdDataViewState {
    metaIdAllViewData:MetaIdDataAllInfoInterface
}
export const state = () => ({
    metaIdAllViewData: {}
})

export const getters = {
    metaIdAllViewData: (state:metaIdDataViewState) => {
        return state.metaIdAllViewData
    }
}

export const mutations = {
    updateMetaIdAllViewData: (state:metaIdDataViewState, payload:any) => {
        state.metaIdAllViewData = payload
    },
    updateCurrentblocktx: (state: metaIdDataViewState, payload: any) => {
      state.metaIdAllViewData.currentblocktx = payload  
    }
}

export const actions = {
    getAllMetIdData({ commit }:{ commit: Function }, payload: any){
        getMetaIdInfoV2(payload || "").then((data: any) => {
            commit('updateMetaIdAllViewData',data)
        }).catch(err => {
        
        })
    },
    updateCurrentblocktx({ commit }: { commit: Function }, payload:any) {
        commit('updateCurrentblocktx',payload)
    }
}
export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}