import config from "@/config";
import { UserInfoTypes } from "@/metaid-types";
import MetaProtocol from "@/utils/classModule/metaProtocol";
import MetaSdk from "@/utils/classModule/metaSdk";
import { getLocal } from "@/utils/storage";

const myGetters = {
  isApp: (state: any) => state.app.appMetaIdJs != undefined,
  isLogined: (state: any) => {
    const accessToken: any = state.app.accessToken || getLocal("accessToken");
    const appAccessToken: any =
      state.app.appAccessToken || getLocal("appAccessToken");
    return (!!accessToken || !!appAccessToken) && state.app.currentUser;
  },
  isToken: (state: any) => {
    const accessToken: any = state.app.accessToken || getLocal("accessToken");
    const appAccessToken: any =
      state.app.appAccessToken || getLocal("appAccessToken");
    return !!accessToken || !!appAccessToken;
  },
  accessToken: (state: any) => {
    if (state.app.accessToken) {
      return state.app.accessToken;
    } else if (getLocal("accessToken")) {
      return getLocal("accessToken");
    } else {
      if (state.app.appAccessToken) {
        return state.app.appAccessToken;
      } else {
        return "";
      }
    }
  },
  appAccessToken: (state: any) => {
    if (state.app.appAccessToken) {
      return state.app.appAccessToken;
    } else if (getLocal("appAccessToken")) {
      return getLocal("appAccessToken");
    } else {
      if (state.app.accessToken) {
        return state.app.accessToken;
      } else {
        return "";
      }
    }
  },
  refreshToken: (state: any) => {
    if (state.app.refreshToken) {
      return state.app.refreshToken;
    } else if (getLocal("refreshToken")) {
      return getLocal("refreshToken");
    } else {
      return "";
    }
  },
  currentUser: (state: any): UserInfoTypes => {
    return state.app.currentUser;
  },
  metaSdk: (state: any): MetaSdk => {
    return state.app.metaSdk;
  },
  metaProtocol: (state: any): MetaProtocol => {
    return state.app.metaProtocol;
  },
  blockTableMaxPages: (state: any): number => {
    if (!state.metaIdDataView.metaIdAllViewData.txcount) {
      return 0;
    } else {
      return Math.ceil(
        state.metaIdDataView.metaIdAllViewData.txcount / config.tablePage
      );
    }
  },
  mcDetailTableMaxPages: (state: any): number => {
    let pages = 1;
    let tableAllData = state.mcBlock.tableAllData.length;
    if (tableAllData) {
      pages = Math.ceil(tableAllData / config.tablePage);
    }
    return pages;
  },
  mcHistoryTableMaxPages: (state: any): number => {
    let pages = 1;
    let tableAllData = state.mcDetail.tableAllData.length;
    if (tableAllData) {
      pages = Math.ceil(tableAllData / config.tablePage);
    }
    return pages;
  },
};

export default myGetters;
