import config from "@/config";
import {
  SendMetaDataTxParams,
  UserInfoTypes,
  PayToItemInterface,
} from "@/metaid-types";
/**
 * 关注
 */
export interface PayFollowProtocolInterface {
  createTime: number; //"{创建时间}";
  MetaID: string; //"{关注的MetaID}";
  pay: string; //"{向payTo指定用户支付的金额}";
  payTo: string; //"{支付对象的地址}";
}
/**
 * 点赞
 */
export interface PayLikeProtocolInterface {
  createTime: number; // "{时间戳}";
  isLike: string; // "{整型数字}";
  likeTo: string; // "{点赞的TxID}";
  pay: string; // "{金额数量，单位聪}";
  payTo: string; // "{用户的metaid或metaid对应顶点的bitcoin address}";
}
/**
 * 评论
 */
export interface PayCommentProtocolInterface {
  createTime: number; //"{创建时间}",
  content: string; //"{评论内容}",
  contentType: string; //"{内容格式}",
  commentTo: string; //"{评论是针对某Tx}"，
  pay: string; //"{支付金额}",
  payTo: string; //"{用户的metaid或metaid对应顶点的bitcoin address}"
}

export interface MetaFileProtocolInterface {
  nodeName: string; //为该文件的文件名
  data: string; // 存放二进制流数据
  encrypt: string; // 是否加密 定义文件是否加密，需应用根据实际需要设定
  version: string; // 文件的版本号 本协议支持Metanet的版本控制功能
  dataType: string; // 文件的格式类型 如“image/jpeg”, “applicaiton/pdf”等
  encoding: string; // 固定为"binary"
}

export interface MetaFileAttachmentInterface {
  fileName: string;
  fileType: string;
  data: string; //Buffter.toString('hex)
}

export interface MetaAccessContentInterface {
  createTime: number;
  updateTime: number;
  metaAccessService: string;
  metaAccessServiceConfigMetanetID: string;
  serverPublicKey: string;
  serverCode: string;
  publicContent: string;
  encryptContent: string;
  publicContentType: string;
  encryptContentType: string;
  title: string;
  attachments: Array<any>;
  artCover?: string;
  artMark?: string;
}
export default class MetaProtocol {
  userInfo: UserInfoTypes;
  accessToken: string;
  metaIdTag: string;
  sendMetaDataTx: Function;

  constructor(options: {
    userInfo: UserInfoTypes;
    accessToken: string;
    metaIdTag: string;
    sendMetaDataTx: Function;
  }) {
    this.userInfo = options.userInfo;
    this.accessToken = options.accessToken;
    this.metaIdTag = options.metaIdTag;
    this.sendMetaDataTx = options.sendMetaDataTx;
  }
  private createMetaDataTxParams(
    options: SendMetaDataTxParams
  ): SendMetaDataTxParams {
    const protocolParam: SendMetaDataTxParams = {
      data: "",
      brfcId: "",
      dataType: "application/json",
      payCurrency: config.payCurrency,
      encrypt: 0,
      accessToken: this.accessToken,
      nodeName: "",
      metaIdTag: this.metaIdTag,
      isFirstProtocolChild: options.isFirstProtocolChild,
      path: options.path || "",
    };
    const params = Object.assign({}, protocolParam, options);
    return params;
  }
  payFollow(
    data: PayFollowProtocolInterface,
    payTo: Array<PayToItemInterface>,
    encryt = 0
  ): Promise<SendMetaDataTxParams> {
    return new Promise((resolve, reject) => {
      if (!payTo || !data) {
        return reject("params error");
      }
      const params = this.createMetaDataTxParams({
        accessToken: this.accessToken,
        data: JSON.stringify(data),
        payTo: payTo,
        encrypt: encryt,
        dataType: "application/json",
        brfcId: "8f23b51a047b",
        nodeName: "PayFollow",
        metaIdTag: this.metaIdTag,
        isFirstProtocolChild: false,
        path: "",
      });
      resolve(params);
    });
  }
  /**
   * 生成点赞广播数据
   * @param data
   * @param payTo
   * @param encryt
   */
  payLike(
    data: PayLikeProtocolInterface,
    payTo: Array<PayToItemInterface>,
    encryt = 0
  ): Promise<SendMetaDataTxParams> {
    return new Promise((resolve, reject) => {
      if (!payTo || !data) {
        return reject("params error");
      }
      const params = this.createMetaDataTxParams({
        accessToken: this.accessToken,
        data: JSON.stringify(data),
        payTo: payTo,
        encrypt: encryt,
        dataType: "application/json",
        brfcId: "b4a118f94cf2",
        nodeName: "PayLike",
        metaIdTag: this.metaIdTag,
        isFirstProtocolChild: false,
        path: "/Protocols/PayLike",
        payCurrency: config.payCurrency,
      });
      resolve(params);
    });
  }
  /**
   * 生成评论广播数据
   * @param data
   * @param payTo
   * @param encryt
   */
  payComment(
    data: PayCommentProtocolInterface,
    payTo: Array<PayToItemInterface>,
    encryt = 0
  ): Promise<SendMetaDataTxParams> {
    return new Promise((resolve, reject) => {
      if (!payTo || !data) {
        return reject("params error");
      }
      const params = this.createMetaDataTxParams({
        accessToken: this.accessToken,
        data: JSON.stringify(data),
        payTo: payTo,
        encrypt: encryt,
        dataType: "application/json",
        brfcId: "70b8224e0559",
        nodeName: "PayComment",
        metaIdTag: this.metaIdTag,
        isFirstProtocolChild: false,
        path: "/Protocols/PayComment",
        payCurrency: config.payCurrency,
      });
      resolve(params);
    });
  }

  /**
   *TODO: 发布文件，没完全写好(写错)
   * @param data
   * @param fileName
   * @param fileType
   * @param encryt
   */
  metaFile(data: any, fileName: any, fileType: any, encryt: number = 0) {
    return new Promise((resolve, reject) => {
      if (!data) {
        return reject("params error");
      }
      const params = this.createMetaDataTxParams({
        accessToken: this.accessToken,
        data: data,
        nodeName: fileName,
        encrypt: encryt,
        dataType: fileType,
        isFirstProtocolChild: false,
        metaIdTag: this.metaIdTag,
        path: "/Protocols/MetaFile",
        version: "1.0.0",
        encoding: "binary",
      });
      return this.sendMetaDataTx(params);
    });
  }
  /**
   * 生成MetaAccessContent
   * @param data
   * @param options
   */
  createMetaAccessContent(options: any) {
    return new Promise(async (resolve, reject) => {
      const _params: any = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        brfcId: config.metaAccessContentBrfcId,
        nodeName: "MetaAccessContent-" + config.metaAccessContentBrfcId,
        metaIdTag: this.metaIdTag,
        isFirstProtocolChild: false,
        path: "/Protocols/MetaAccessContent",
        payCurrency: config.payCurrency,
        data: "",
      };
      const params = Object.assign({}, _params, options);
      resolve(params);
    });
  }
  /**
   * 生成MetaAccessServiceConfig params
   * @param options
   * @param publickey
   */
  createMetaAccessServiceConfig(options?: any, publickey?: string) {
    return new Promise(async (resolve, reject) => {
      //测试数据 txid:"281295cd2bd102ff504ecfc16a68260385ce22a448d17818577504e48adafee7"
      const data = {
        createTime: Date.now(),
        updateTime: Date.now(),
        metaID: config.main_net
          ? "b371fd7e895f107b300ed79b24da050a129c570d783b9e75a98478eb54df17e6"
          : "15794dfd9f9ae251438e7b940c6f643b3451cded57ab1983481f442ad3cf9be3",
        name: config.main_net ? "Showman" : "ShowmanTest",
        fee: config.main_net ? "0" : "1000",
        url: config.main_net
          ? "https://testshowman.showpay.io/metaaccess/"
          : "https://dev2showman.showpay.top/metaaccess/",
      };
      const params: any = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        data: JSON.stringify(data),
        path: "/Protocols/MetaAccessServiceConfig",
        brfcId: config.metaAccessServiceConfigBrfcId,
        nodeName:
          publickey ||
          "MetaAccessServiceConfig-" + config.metaAccessServiceConfigBrfcId,
        metaIdTag: this.metaIdTag,
        payCurrency: config.payCurrency,
      };
      resolve(params);
    });
  }
  createMetaAccess(options: any) {
    return new Promise(async (resolve, reject) => {
      const _params = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        brfcId: config.metaAccessBrfcId,
        nodeName: "MetaAccess-" + config.metaAccessBrfcId,
        metaIdTag: this.metaIdTag,
        path: "/Protocols/MetaAccess",
        data: "",
      };
      const params = Object.assign({}, _params, options);
      resolve(params);
    });
  }

  createMetaAccessPay(options: any) {
    return new Promise(async (resolve, reject) => {
      const _params = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        brfcId: config.metaAccessPayBrfcId,
        nodeName: "MetaAccessPay-" + config.metaAccessPayBrfcId,
        metaIdTag: this.metaIdTag,
        path: "/Protocols/MetaAccessPay",
        data: "",
      };
      const params = Object.assign({}, _params, options);
      resolve(params);
    });
  }
  createSimpleSubscribe(payTo: any, data: any) {
    return new Promise(async (resolve, reject) => {
      const _params = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        brfcId: config.simpleSubscribeBrfcId,
        nodeName: "SimpleSubscribe-" + config.simpleSubscribeBrfcId,
        metaIdTag: this.metaIdTag,
        path: "/Protocols/SimpleSubscribe",
        payTo: payTo,
        data: JSON.stringify(data),
        payCurrency: config.payCurrency,
      };
      const params = Object.assign({}, _params);
      resolve(params);
    });
  }
  createSimpleCancleSubscribe(data: any) {
    return new Promise(async (resolve, reject) => {
      const _params = {
        accessToken: this.accessToken,
        encrypt: 0,
        dataType: "application/json",
        brfcId: config.simpleCancleSubscribeBrfcId,
        nodeName: "SimpleCancleSubscribe-" + config.simpleCancleSubscribeBrfcId,
        metaIdTag: this.metaIdTag,
        path: "/Protocols/SimpleCancleSubscribe",
        data: JSON.stringify(data),
      };
      const params = Object.assign({}, _params);
      resolve(params);
    });
  }
}
