

























































import Vue from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import LoginArea from "@/components/common/loginArea.vue";
import LanSelect from "@/components/common/lanSelect.vue";
import SearchInput from "@/components/common/SearchInput.vue";
import HeaderTabs from "@/components/common/HeaderTabs.vue";
import Component from "vue-class-component";
import { appDispath } from "./store/modules/app";
import { TokenObjType } from "./metaid-types";
import { getAccessToken, getRefreshToken } from "./api/api_helper";
import { Getter } from "vuex-class";
import config from "./config";

const logo = require("@/assets/image/logo_meta@2x.png");
@Component({
  name: "App",
  components: {
    HelloWorld,
    LoginArea,
    LanSelect,
    SearchInput,
    HeaderTabs,
  },
})
export default class App extends Vue {
  logo: any = logo;
  @Getter("app/isMobile") isMobile!: boolean;
  @Getter("app/code") code!: boolean;
  @Getter("isLogined") isLogined!: boolean;
  @Getter("isToken") isToken!: boolean;
  @Getter("refreshToken") refreshToken!: string;
  @Getter("isApp") isApp!: boolean;

  subjectFiling: string = config.subjectFiling;
  subjectFilingUrl: string = config.subjectFilingUrl;
  includeList: Array<string> = ["Home"];
  async created() {
    if (!this.isApp) {
      if (!this.isToken) {
        const code: any = this.$route.query.code;
        if (code) {
          this.$store.dispatch(appDispath.updateUserLogining, true);
          this.$store.dispatch(appDispath.updateCode, code);
          const res: any = await getAccessToken(code).catch((err) => {
            // this.$store.dispatch(appDispath.loginOut);
            this.logoutApp();
          });
          if (res) {
            this.$store.dispatch(
              appDispath.updateAccessToken,
              res.access_token
            );
            this.$store.dispatch(
              appDispath.updateRefreshToken,
              res.refresh_token
            );
          }
        }
      } else {
        this.$store.dispatch(appDispath.updateUserLogining, true);
        // TODO:些时，用户token在缓存，需刷新token并获取用户信息
        if (!this.refreshToken) {
          this.$store.dispatch(appDispath.updateUserLogining, false);
          return;
        }
        const result: TokenObjType | void = await getRefreshToken(
          this.refreshToken
        ).catch((err: any) => {
          /**
           * 刷新refreshToken出错退出登录
           */
          // this.$store.dispatch(appDispath.loginOut);
          this.logoutApp();
        });
        console.log('result',result)
        if (result) {
          this.$store.dispatch(
            appDispath.updateAccessToken,
            result.access_token
          );
          this.$store.dispatch(
            appDispath.updateRefreshToken,
            result.refresh_token
          );
        }
        //TODO:可能需要跳转页面
      }
    }
  }
  mounted() {
  }
}
