import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from "vuetify/lib";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import VueI18n from "vue-i18n"

Vue.component("font-awesome-icon", FontAwesomeIcon); // 全局注册组件
library.add(fas); // 包含需要的图标
Vue.use(Vuetify);
Vue.use(VueI18n);
let zh_json = require("@/assets/lang/cn.json");
let en_json = require("@/assets/lang/en.json");
// zh_json["$vuetify"] = {
// dataIterator: {
//         rowsPerPageText: 'Items per page:',
//         pageText: '{0}-{1} of {2}',
//       },

// }
// en_json["vuetify"] = {
//   dataIterator: {
//         rowsPerPageText: 'Items per page:',
//         pageText: '{0}-{1} of {2}',
//       },

// }
// zh_json
      
    
export const i18n = new VueI18n({
  locale: 'zhHans',
  messages: {
    zhHans: {
      $vuetify: zh_json,
    },
    en: {
      $vuetify: en_json,
    }
  }
})

export default new Vuetify({
  icons: {
    iconfont: "faSvg",
  },
  lang:{
    t: (key, ...params) => {
      console.log("lang.t:", key, i18n.t(key))
      return i18n.t(key, params) as string
    },
  }
});
