

































































































































































import { MetaIdDataAllInfoInterface } from "@/store/modules/metaIdDataView";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ItemBlock from "./ItemBlock.vue";
const dotIcon = require("@/assets/image/dot@2x.png");
@Component({
  name: "MetaIdAllView",
  components: {
    ItemBlock,
  },
})
export default class MetaIdAllView extends Vue {
  @Getter("metaIdDataView/metaIdAllViewData")
  viewData!: MetaIdDataAllInfoInterface;
  dotIcon: any = dotIcon;

  get amount() {
    if (this.viewData.amount) {
      // return this.viewData.amount / Math.pow(10, 8);
      return this.viewData.amount;
    } else {
      return 0;
    }
  }
  get currentblockamount() {
    if (this.viewData.currentblockamount) {
      // return this.viewData.currentblockamount / Math.pow(10, 8);
      return this.viewData.currentblockamount;
    } else {
      return 0;
    }
  }
  mounted() {
    this.$store.dispatch("metaIdDataView/getAllMetIdData");
  }
}
