












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import countTo from "vue-count-to";

@Component({
  name: "MyCountTo",
  components: {
    countTo,
  },
})
export default class MyCountTo extends Vue {
  @Prop({
    default: 0,
  })
  value!: string | number;
  @Prop({
    type: String,
    default: "",
  })
  prefix!: string;
  @Prop({
    type: String,
    default: "",
  })
  suffix!: string;
  @Prop({
    type: String,
    default: ",",
  })
  separator!: string;
  @Prop({
    type: Number,
    default: 1500,
  })
  duration!: Number;
  @Prop({
    type: Number,
    default: 0,
  })
  decimals!: Number;
  decimals_test: Number = 0;
  get numberValue(): any {
    if (this.value) {
      return new Number(this.value);
    } else {
      return 0;
    }
  }
  get numberStart() {
    return 0;
    if (this.numberValue > 30) {
      return this.numberValue - 30;
    } else {
      return 0;
    }
  }
  get isDecimal() {
    const rgx = /[.]/;
    const val = this.value.toString();
    const val_arr = val.split(".");
    if (val_arr.length > 1) {
      if (val_arr[1].length < this.decimals) {
        this.decimals_test = val_arr[1].length;
      }
    }
    return rgx.test(val);
  }
}
