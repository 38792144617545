// import "core-js/stable";
// import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import "@/assets/svg/svg.ts";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import config from "./config";
import { appDispath } from "./store/modules/app";
import MetaSdk from "./utils/classModule/metaSdk";
import { getShowAccount } from "./api";
import MetaProtocol from "./utils/classModule/metaProtocol";
import MetaIdJs from "metaidjs";
import ZangoDb from "@/plugins/zangoDb.js";
import * as myFilter from "./filter/index";
import "@/assets/style/index.scss";
import MetaIdData from "./utils/classModule/metaData";
import { WriteHeaderPath } from "./store/modules/writeHeader";
import { i18n } from "@/plugins/vuetify";

// import "lib-flexible/flexible.js";

Vue.config.productionTip = false;
Vue.use(ZangoDb);

// Vue.prototype.$showLogin = () => {
//   if (!store.getters.isLogined) {
//     Dialog.confirm({
//       title: "提示",
//       message: "请登录账户",
//       confirmButtonText: "登录",
//     })
//       .then(() => {
//         goAuth();
//       })
//       .catch(() => {
//         console.log("close showLoginDialog");
//       });
//   }
// };
Vue.prototype.MetaIdData = MetaIdData;
Object.keys(myFilter).forEach((key: string) => {
  Vue.filter(key, (myFilter as any)[key]);
});
Vue.mixin({
  methods: {
    logoutApp: function() {
      this.$store.dispatch(appDispath.updateUserLogining, false);
      this.$store.dispatch(appDispath.loginOut);
      // this.$store.dispatch(WriteHeaderPath.updateNewArticle);
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  data: () => {
    return {
      handleUserInfo: (res: any) => {},
    };
  },
  created() {
    console.log("main create");
    /**
     * 钱包登录后回调
     * @param res
     */
    const handleUserInfo = (res: any): any => {
      const that = this;
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      if (res.code === 200) {
        const userInfo = res.data;
        userInfo.metaId = userInfo.metaId ? userInfo.metaId : userInfo.showId;
        if (res.appAccessToken)
          this.$store.dispatch(
            appDispath.updateAppAccessToken,
            res.appAccessToken
          );
        getShowAccount(userInfo.showId);

        /**
         * 实例后保存metasdk在store
         */
        const metaSdk = new MetaSdk(
          this.$store.getters.isApp,
          this.$store.getters.accessToken,
          this.$metaidjs
        );
        this.$store.dispatch(appDispath.updateMetaSdk, metaSdk);
        this.$store.dispatch(appDispath.updateCurrentUser, userInfo);
        this.$store.dispatch(appDispath.updateUserLogining, false);
        /**
         * 实例 MetaProtocol
         *
         */
        this.$metaProtocol = new MetaProtocol({
          userInfo: this.$store.getters.currentUser,
          accessToken: this.$store.getters.isApp
            ? this.$store.getters.appAccessToken
            : this.$store.getters.accessToken,
          metaIdTag: config.metaIdTag,
          sendMetaDataTx: metaSdk.sendMataData,
        });
        this.$store.dispatch(appDispath.updateMetaProtocol, this.$metaProtocol);

        //测试实例Info metaData
        // getUserInfoMetaData(userInfo.showId).then(async (r: any) => {
        //   var c = new MetaIdData(
        //     r.data[0],
        //     metaSdk,
        //     this.$metaProtocol,
        //     this
        //   );
        //   await c.getUserInfo();
        // });
        // 一秒后获取用户信息
        // setTimeout(() => {
        //   this.$MetaIdDataAdapter
        //     .getUserInfo(userInfo.showId, false)
        //     .then(async (newUserInfo: any) => {
        //       // 移除获获取关注数据,保留登陆返回数据

        //       // const followData = await getUserFollowData(userInfo.showId)
        //       // console.log('userInfo', Object.assign(newUserInfo, followData))
        //       store.dispatch(
        //         "updateUserInfo",
        //         // Object.assign(newUserInfo, followData)
        //         newUserInfo
        //       );
        //     });
        //   store.dispatch("updateAppAccessToken", res.appAccessToken);
        // }, 1000);
        this.MetaIdData.metaProtocol = this.$metaProtocol;
        this.MetaIdData.metaSdk = metaSdk;

        // Toast.clear();
        // if (this.$route.query.code) {
        //   this.$router.replace({
        //     name: "Home",
        //   });
        // }
      } else {
        // this.$toasted.error("Get user info failed");
        console.error(res.data.message);
        this.logoutApp();
      }
      // return (res: any) => {
      // };
    };
    if (!window.handleUserInfo) {
      window["handleUserInfo"] = this.handleUserInfo = handleUserInfo;
    }
    // 是否嵌入APP
    console.log("this.$store", this.$store);
    if (this.$store.getters.isApp) {
      store.dispatch("updateIsAppStatus", true);
      const appClientId = process.env.VUE_APP_clientId;
      const appClientSecret = process.env.VUE_APP_clientSecret;
      // Toast.loading();
      window.appMetaIdJs.getUserInfo(
        appClientId,
        appClientSecret,
        "handleUserInfo"
      );
    } else {
      console.log("不是showApp打开,isApp:", this.$store.getters["app/isApp"]);
      // TODO:不是showApp打开要做的事情
    }
    console.log("store.getters.isToken", store.getters.isToken);
    store.watch(
      () => store.getters.accessToken,
      (val) => {
        if (val) {
          console.log("已登录");
          if (!store.getters.isApp) {
            const metaidjsInitParam = {
              baseUri: process.env.VUE_APP_SHOW_MONEY,
              oauthSettings: {
                clientId: process.env.VUE_APP_clientId || '',
                clientSecret: process.env.VUE_APP_clientSecret  || '',
                redirectUri: process.env.VUE_APP_redirectUri  || ''
              },
              onError: (error: any) => {
                if (error.code === 201 || error.code === 202) {
                  // store.dispatch(appDispath.loginOut);
                  this.logoutApp();
                }
                // Vue.prototype.$toasted.error(error.data.message);
              },
              onLoaded: (r: any) => {
                if (metaidjs.isLoaded) {
                  store.dispatch(
                    appDispath.updateMetaIdJsStatus,
                    metaidjs.isLoaded
                  );
                  // Toast.loading();
                  console.log(
                    "store.getters.accessToken:",
                    store.getters.accessToken
                  );
                  const tk = store.getters.accessToken;
                  metaidjs.getUserInfo({
                    accessToken: store.getters.accessToken || "",
                    callback: (res: any) => {
                      console.log(
                        "刷新了的accessToken:",
                        store.getters.accessToken
                      );
                      console.log("metaidjs.getUserInfo res:", res);
                      window.handleUserInfo(res);
                    },
                  });
                }
              },
            };
            var metaidjs: any = null;
            if (this.$metaidjs) {
              store.dispatch(
                appDispath.updateMetaIdJsStatus,
                this.$metaidjs.isLoaded
              );

              // Toast.loading();
              this.$metaidjs.getUserInfo({
                accessToken: store.getters.accessToken || "",
                callback: (res: any) => {
                  window.handleUserInfo(res);
                },
              });
            } else {
              console.log("metaidjsInitParam:", metaidjsInitParam);
              metaidjs = new MetaIdJs(metaidjsInitParam);
              Vue.prototype.$metaidjs = this.$metaidjs = metaidjs;
              // setTimeout(() => {
              //   console.log("metaidjs", metaidjs);
              // }, 5000);
            }

            // TODO:多次实例MetaIdJs会出现生成多个meta_frame,会有多次onLoaded回调。监听实例的isLoaded为true的变化时没监听到
            // store.watch(
            //   () => this.$metaidjs.isLoaded,
            //   (isLoaded) => {
            //     store.dispatch("app/updateMetaIdJsStatus", isLoaded);
            //     if (isLoaded) {
            //       metaidjs.getUserInfo({
            //         accessToken: store.state.accessToken || "",
            //         callback: (res: any) => {
            //           this.handleUserInfo(res);
            //         },
            //       });
            //     }
            //   },
            //   // { immediate: true }
            // );
          }
        } else {
        }
      }
    );
    //第一次打开app要做获取MetaAccessServiceConfig
    // this.$store.dispatch(WriteHeaderPath.updateAuthList);
  },
  render: (h) => h(App),
}).$mount("#app");
