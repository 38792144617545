import { getAllMetaAccessServiceConfig } from "@/api/api_helper";

export interface ArticleAuthAccessServiceInterface {
  createTime: string;
  metaID: string;
  name: string;
  updateTime: string;
  fee: string;
  metanetId: string;
  url: string;
  txId: string;
}
export interface ArticleInputUnitInterface {
  sats: number;
  unit: string;
  unitId: number;
}
export interface ArticleSettingInterface {
  uuid: string; //文章唯一id
  isFree: boolean; //是否免费文章
  auth: ArticleAuthAccessServiceInterface; //付费阅读/授权访问服务商
  uploadFiles: Array<any>; //封面图，暂一张
  remarkInput: string; //摘要
  inputUnit: ArticleInputUnitInterface;
  inputAmount: number;
  freeContent: string | null; //付费后部份免费可见文章内容
  payContent: string | null; //需付费后才可见的文章内容
  saveTime: number | null;
  createTime: number | null;
  amountListCurrentIndex: number;
}
export interface ArticleWriteState {
  articleTitle: string;
  articleContent: string;
  setArticleContent: string;
  articleImg: Array<any>;
  saveTime: number | null;
  freeContent: string | null;
  payContent: string | null;
  contentDeltas: any;
  isFree: boolean;
  templateArticle: any;
  artSetting: ArticleSettingInterface;
  authList: Array<ArticleAuthAccessServiceInterface>;
  articleTypes: Array<any>;
  amountList: Array<any>;
  unitList: Array<ArticleInputUnitInterface>;
  amountListCurrentIndex: number;
}

function createArtSetting(
  auth: ArticleAuthAccessServiceInterface = {
    createTime: "",
    updateTime: "",
    metaID: "1",
    name: "Showman",
    fee: "",
    metanetId: "",
    txId: "",
    url: "https://testshowman.showpay.io/metaaccess/",
  }
) {
  const artSetting: ArticleSettingInterface = {
    uuid: "",
    isFree: true,
    auth: auth,
    uploadFiles: [],
    remarkInput: "",
    inputUnit: {
      unit: "BSV",
      unitId: 1,
      sats: Math.pow(10, 8),
    },
    inputAmount: 0.001,
    freeContent: "",
    payContent: "",
    saveTime: null,
    createTime: null,
    amountListCurrentIndex: 0,
  };
  return artSetting;
}

export const state = (): ArticleWriteState => ({
  articleTitle: "",
  articleContent: "",
  setArticleContent: "",
  articleImg: [],
  saveTime: null,
  freeContent: null, //付费后部份免费可见文章内容
  payContent: null, //需付费后才可见的文章内容
  contentDeltas: null,
  isFree: true, //是否免费文章
  templateArticle: null,
  artSetting: createArtSetting(),
  amountListCurrentIndex: 0,
  authList: [
    // {
    //   name: "ShowMan",
    //   id: 1,
    // },
    {
      createTime: "",
      updateTime: "",
      metaID: "1",
      name: "Showman",
      fee: "",
      metanetId: "",
      txId: "",
      url: "https://testshowman.showpay.io/metaaccess/",
    },
  ],
  articleTypes: [
    {
      name: "免费",
      id: 0,
    },
    {
      name: "付费",
      id: 1,
    },
  ],
  amountList: [
    {
      val: 0.001,
      unit: "BSV",
      unitId: 1,
      id: 0,
      sats: Math.pow(10, 8),
    },
    {
      val: 0.01,
      unit: "BSV",
      unitId: 1,
      id: 1,
      sats: Math.pow(10, 8),
    },
    {
      val: 0.002,
      unit: "BSV",
      unitId: 1,
      id: 2,
      sats: Math.pow(10, 8),
    },
    {
      val: 0.05,
      unit: "BSV",
      unitId: 1,
      id: 3,
      sats: Math.pow(10, 8),
    },
    {
      val: 0.1,
      unit: "BSV",
      unitId: 1,
      id: 4,
      sats: Math.pow(10, 8),
    },
    {
      val: 1,
      unit: "BSV",
      unitId: 1,
      id: 5,
      sats: Math.pow(10, 8),
    },
  ],
  unitList: [
    {
      unit: "BSV",
      unitId: 1,
      sats: Math.pow(10, 8),
    },
    {
      unit: "BITS",
      unitId: 2,
      sats: Math.pow(10, 2),
    },
    {
      unit: "SATS",
      unitId: 3,
      sats: Math.pow(10, 0),
    },
  ],
});

export const getters = {
  articleTitle: (state: ArticleWriteState) => {
    return state.articleTitle;
  },
  articleContent: (state: ArticleWriteState) => {
    return state.articleContent;
  },
  articleImg: (state: ArticleWriteState) => {
    return state.articleImg;
  },
  saveTime: (state: ArticleWriteState) => {
    return state.saveTime;
  },
  authList: (state: ArticleWriteState) => {
    return state.authList;
  },
  articleTypes: (state: ArticleWriteState) => {
    return state.articleTypes;
  },
  amountList: (state: ArticleWriteState) => {
    return state.amountList;
  },
  unitList: (state: ArticleWriteState) => {
    return state.unitList;
  },
  freeContent: (state: ArticleWriteState) => {
    return state.freeContent;
  },
  payContent: (state: ArticleWriteState) => {
    return state.payContent;
  },
  isFree: (state: ArticleWriteState) => {
    return state.isFree;
  },
  contentDeltas: (state: ArticleWriteState) => {
    return state.contentDeltas;
  },
  templateArticle: (state: ArticleWriteState) => {
    return state.templateArticle;
  },
  artSetting: (state: ArticleWriteState) => {
    return state.artSetting;
  },
  setArticleContent: (state: ArticleWriteState) => {
    return state.setArticleContent;
  },
  amountListCurrentIndex: (state: ArticleWriteState) => {
    return state.amountListCurrentIndex;
  },
};

export const mutations = {
  updateArticleTitle: (state: ArticleWriteState, payload: any) => {
    state.articleTitle = payload;
  },
  updateArticleContent: (state: ArticleWriteState, payload: any) => {
    state.articleContent = payload;
  },
  updateSaveTime: (state: ArticleWriteState, payload: any) => {
    if (payload) {
      state.saveTime = payload;
    } else {
      state.saveTime = Date.now();
    }
    state.artSetting.saveTime = state.saveTime;
  },
  updateFreeContent: (state: ArticleWriteState, payload: any) => {
    state.artSetting.freeContent = payload;
    state.freeContent = payload;
  },
  updatePayContent: (state: ArticleWriteState, payload: any) => {
    state.artSetting.payContent = payload;
    state.payContent = payload;
  },
  updateArticleImg: (state: ArticleWriteState, payload: any) => {
    if (payload instanceof Array) {
      state.articleImg = payload;
    } else {
      state.articleImg.push(payload);
    }
  },
  updateNewArticle: (state: ArticleWriteState) => {
    state.articleTitle = "";
    state.articleContent = "";
    state.articleImg = [];
    state.saveTime = null;
    state.freeContent = null;
    state.payContent = null;
    state.isFree = true;
    state.artSetting = createArtSetting(state.authList[0]);
  },
  updateIsFree: (state: ArticleWriteState, isFree: boolean) => {
    if (!isFree) {
      /**
       * 是否选择免费
       */
      if (!state.payContent) {
        //付费没内容
        state.payContent = state.articleContent;
        state.freeContent = "";
      } else {
        if (!state.freeContent) {
          state.payContent = state.articleContent;
          state.freeContent = "";
        }
      }
    } else {
      state.payContent = "";
      state.freeContent = state.articleContent;
    }
    state.payContent = state.payContent.replace(
      '<section class="free">',
      "<section>"
    );
    state.freeContent = state.freeContent.replace(
      "<section>",
      '<section class="free">'
    );
    state.artSetting.payContent = state.payContent;
    state.artSetting.freeContent = state.freeContent;
    state.setArticleContent = state.freeContent + state.payContent;
    state.artSetting.isFree = isFree;
  },
  updateContentDeltas: (state: ArticleWriteState, payload: any) => {
    state.contentDeltas = payload;
  },
  updateTemplateArticle: (state: ArticleWriteState) => {
    state.templateArticle = {
      uuid: "",
      article: {
        title: state.articleTitle,
        content: state.articleContent,
        setting: state.artSetting,
      },
    };
  },
  updateArtSetting: (state: ArticleWriteState, payload: any) => {
    state.artSetting = payload;
  },
  updateAuthList: (state: ArticleWriteState, payload: any) => {
    state.artSetting = createArtSetting(payload[0]);
    state.authList = payload;
  },
  updateSetArticleContent: (state: ArticleWriteState, payload: any) => {
    if (state.payContent == "") {
      state.artSetting.isFree = true;
    }
    state.setArticleContent = payload;
  },
  updateAmountListCurrentIndex: (state: ArticleWriteState, payload: any) => {
    state.amountListCurrentIndex = payload;
  },
  updateArtUUID: (state: ArticleWriteState, payload: any) => {
    state.artSetting.uuid = payload;
  },
};
export const WriteHeaderPath = {
  updateArticleTitle: "writeHeader/updateArticleTitle",
  updateArticleContent: "writeHeader/updateArticleContent",
  updateArticleImg: "writeHeader/updateArticleImg",
  updateSaveTime: "writeHeader/updateSaveTime",
  updateFreeContent: "writeHeader/updateFreeContent",
  updatePayContent: "writeHeader/updatePayContent",
  updateNewArticle: "writeHeader/updateNewArticle",
  updateIsFree: "writeHeader/updateIsFree",
  updateContentDeltas: "writeHeader/updateContentDeltas",
  updateTemplateArticle: "writeHeader/updateTemplateArticle",
  updateArtSetting: "writeHeader/updateArtSetting",
  updateAuthList: "writeHeader/updateAuthList",
  updateSetArticleContent: "writeHeader/updateSetArticleContent",
  updateAmountListCurrentIndex: "writeHeader/updateAmountListCurrentIndex",
  updateArtUUID: "writeHeader/updateArtUUID",
};
export const actions = {
  updateArticleTitle({ commit }: { commit: Function }, payload: any) {
    commit("updateArticleTitle", payload);
  },
  updateArticleContent({ commit }: { commit: Function }, payload: any) {
    commit("updateArticleContent", payload);
  },
  updateArticleImg({ commit }: { commit: Function }, payload: any) {
    commit("updateArticleImg", payload);
  },
  updateSaveTime({ commit }: { commit: Function }, payload: any) {
    //TODO:设置自动保存时间前先做保存业务
    commit("updateSaveTime", payload);
  },
  updateFreeContent({ commit }: { commit: Function }, payload: any) {
    commit("updateFreeContent", payload);
  },
  updatePayContent({ commit }: { commit: Function }, payload: any) {
    commit("updatePayContent", payload);
  },
  updateNewArticle({ commit }: { commit: Function }) {
    commit("updateNewArticle");
  },
  updateIsFree({ commit }: { commit: Function }, payload: any) {
    commit("updateIsFree", payload);
  },
  updateContentDeltas({ commit }: { commit: Function }, payload: any) {
    commit("updateContentDeltas", payload);
  },
  updateTemplateArticle({ commit }: { commit: Function }) {
    commit("updateTemplateArticle");
  },
  updateArtSetting({ commit }: { commit: Function }, payload: any) {
    commit("updateArtSetting", payload);
  },
  updateAuthList({ commit }: { commit: Function }, payload: any) {
    getAllMetaAccessServiceConfig().then((r: any) => {
      const dataList: Array<any> = r.data;
      const data: Array<any> = [];
      dataList.forEach((item) => {
        data.push(
          Object.assign({}, item.data, {
            metanetId: item.metanetId,
            publicKey: item.publicKey,
            txId: item.txId,
          })
        );
      });
      commit("updateAuthList", data);
    });
  },
  updateSetArticleContent({ commit }: { commit: Function }, payload: any) {
    commit("updateSetArticleContent", payload);
  },
  updateAmountListCurrentIndex({ commit }: { commit: Function }, payload: any) {
    commit("updateAmountListCurrentIndex", payload);
  },
  updateArtUUID({ commit }: { commit: Function }, payload: any) {
    commit("updateArtUUID", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
