export interface ConfigTypes {
  readonly baseUrl: string; // 当前项目基本Url
  readonly showMoneyUrl: string; // Showmoney 钱包地址
  readonly metaFileServiceUrl: string; // MetaFile 服务Url
  readonly metaIdServiceUrl: string; // MetaId 数据服务Url
  readonly metaIdCenterUrl: string; // MetaId tag 服务Url
  readonly metaIdTag: string;
  readonly env: string;
  readonly basePath: string; // Vue router basePath
  readonly projectAddress: string; // 项目方收款地址
  readonly payCurrency: string; // 结算币种，支持bsv/usd，bsv的结算单位为聪
  readonly simpleBlogFee: number; // 发表Buzz项目方收取的费用
  readonly payLikeAmount: number; // 点赞支付给原作者的金额
  readonly payLikeFee: number; // 点赞项目方收取的费用
  readonly payLikeBrfcId: string; //点赞BrfcId
  readonly payCommentAmount: number; // 评论支付给原作者的金额
  readonly payCommentFee: number; // 评论项目方收取的费用
  readonly payCommentBrfcId: string; //评论BrfcId
  readonly payFollowAmount: number; // 关注支付给原作者的金额
  readonly payFollowFee: number; // 关注项目方收取的费用
  readonly payFollowBrfcId: string; //关注BrfcId
  readonly rePostAmount: number; // 转发支付给原作者的金额
  readonly rePostFee: number; // 转发项目方收取的费用
  readonly rePostBrfcId: string; //转发BrfcId
  readonly oauthSettings: OauthSettings;
  readonly appOauthSettings: OauthSettings;
  readonly recommandUsers: string[];
  readonly hotBuzz: string[];
  readonly CourseType: CourseType;
  readonly webConfigRefreshTime: number; // 获取在线config数据的有效时间
  readonly nodeName: string;
  readonly parentNodeName: string;
  readonly webConfigMetaId: string;
  readonly subjectFilingUrl: string;
  readonly subjectFiling: string;
  readonly autoSaveArtTime: number; //定时保存文章时间
  readonly metaFileBrfcId: string;

  readonly metaAccessContentBrfcId: string;
  readonly metaAccessServiceConfigBrfcId: string;
  readonly metaAccessBrfcId: string;
  readonly metaAccessPayBrfcId: string;
  readonly simpleSubscribeBrfcId: string;
  readonly simpleCancleSubscribeBrfcId: string;
  readonly main_net: boolean;

  readonly simpleSubscribeAmount: number;
  readonly simpleSubscribeFee: number;
  readonly tablePage: number;
  mcBlockTablePageMaxSize?: number;
  mcBlockSize?: number;
}

interface CourseType {
  readonly hot: string;
  readonly lastNew: string;
}
interface OauthSettings {
  readonly clientId: string;
  readonly clientSecret: string;
  readonly redirectUri: string;
}

// const domain: string = getBaseDomain()

const type: string = process.env.VUE_APP_TYPE || "test";

const testRecommandUsers = [
  "3e83f04a92c7a8c0e051fc6b813f3f59e643b1a64e8f75b030da8f0969b3ee4f",
  "0a9d30a38c44aba79b2d023d667ca4558350727eb0b71efdff6aa6695200f78f",
  "0a1d64ddc4c2b4f42f90f8bd9d7455ad14a0b0f52cf485b45e5421f23a131c8b",
];
const prodRecommandUsers: string[] = [
  "2df27132058cd24ff9ef2939315c9ca0d8ec88733f5bda0df130b7798efea972",
  "ece4ee8945fecd67c49eaab633c8bf36926692bbdb80b60db5a4063ee7b561eb",
  "a7cc5c67d67e792bedeb6e2f7dee30a8159bd893a5207fc01646b0565a5d93d9",
  "ff7ab1d48e34355eefb7356ba0b9f6fc517fc37d514e544bc8f524596aa008ad",
  "ca6bd3eb2bc6abb6c50c4e6a09b1eaf51ac74a2648df5d1f79285f527f8df056",
  "851c39cc0e9ff33e800451164bf5d384196b782361e11be42fc2f8bca251971f",
  "b0724df68cbe2851059c7345759e926de877e734d0991993a2d340f9e4e33940",
  "9d92fecde784d6e81a578b1f8dfd29a7ca9a295d0cf1368209271f0ece300e1e",
];
const testHotBuzz = [
  "d05ae4a4f1d4cf7eb9ee878d64964058dd309690401ebdb27256039e16268312",
  "0a95a4c9f0e8a1517efe7586fc96d994ab39dacaf229d77768dd0d70bff2d394",
];
const prodHotBuzz: string[] = [
  "999f095807f347c26f59fce4c54125b64645f6fe544f85846f90d301bda9fd8c",
  "4ba3579d6d0f54c9518984c46bef7f2a3864a62eddedd3a25adfe224adb1b265",
  "371476a0f776c2a24f42975f7b20671265e08ee210e3b815ace6b87b1cc02a85",
  "5e9932bb9adbf18227a25100c467e50c1425dcfba52b826d2604bb41e9792d76",
  "5b591c94516091d8cbe16b8c75fa9abecc332e06b1e0be8449aa2a9ce81c6737",
  "78f780df6e53742810853063154d46505fbc33280016c3b50112fda411654585",
  "64b6185f419705d87ac4a6d0fd04c96747da3d326c6e88b8914a28c3e73fcded",
  "6ec3e16e233aac5fcd369bafa174ff9f49e90c7f26d1c5ab90cdb68b71d36ee5",
  "5029774a287d0420d6800487707c766b6cdc082caca24790847d92d64f144f86",
  "fa64c3512a2c778408f8052b2b914a54c51971b788bb1a8d0eb53efaf90a22ee",
  "0afc3400c326317a96fa0458f11f6876804223007c697fc0e661ca5f08937191",
  "8e4e6e27102118d04716f258342af66d7c9d0d076bdc02ac16bbe4b9424f8e90",
  "c0c82f4761b5adf3503b183326ad603867afc24e83d78d4485c26bc2fe5bca56",
];
const main = process.env.VUE_APP_MAIN_NETWORK ? "main" : "test";
const main_network = process.env.VUE_APP_MAIN_NETWORK == "true";
console.log("main_network", main_network);
const config: ObjTypes<ConfigTypes> = {
  dev: {
    baseUrl: main_network
      ? "https://dev.showmoney.io"
      : "https://dev.showmoney.io",
    showMoneyUrl: main_network
      ? "http://testshowmoney.showpay.top/"
      : "https://dev2showmoney.showpay.top",
    metaFileServiceUrl: main_network
      ? "https://testshowman.showpay.io"
      : "https://dev2showman.showpay.top",
    metaIdServiceUrl: main_network
      ? "https://test.showmoney.io/api"
      : "https://dev2showmoney.showpay.top/api",
    metaIdCenterUrl: main_network
      ? "https://test.showmoney.app/tag"
      : "https://dev2showmoney.showpay.top/tag",
    basePath: "/",
    metaIdTag: "testshowid",
    env: type,
    projectAddress: main_network
      ? "15XtDJMeaMAafuVHdxS7Vvu6F8uXF1EU4h"
      : "1F925MpJRX7KJYyZ1KzMZUa96SATkmoBSR",
    payCurrency: "usd",
    simpleBlogFee: 0.0001,
    payLikeAmount: 0.0001,
    payLikeFee: 0.0001,
    payLikeBrfcId: "b4a118f94cf2",
    payCommentFee: 0.0001,
    payCommentAmount: 0.0001,
    payCommentBrfcId: "70b8224e0559",
    payFollowAmount: 0.0001,
    payFollowFee: 0.0001,
    payFollowBrfcId: "8f23b51a047b",
    rePostAmount: 0.0001,
    rePostFee: 0.0001,
    rePostBrfcId: "b51e23269c75",
    oauthSettings: {
      // clientId: main_network
      //   ? "7b8d6f99-6370-4ea9-8066-eaee5a9795e2"
      //   : // : "b42ce3de-61ae-4974-adc1-c8018daeea02",
      //     "74a439d5-4ef8-4e2a-b087-77c7e9cc0b91",
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: main_network
        ? "8f6131db-588f-406f-998b-7bd60c2bb247"
        : // : "370d2fc5-bbd1-441f-bd84-18d2a2ab4135",
          "d582f09f-d9e7-442a-a443-31e2b09c0dde",
      redirectUri: process.env.VUE_APP_redirectUri as string,
    },
    appOauthSettings: {
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: process.env.VUE_APP_clientSecret as string,
      redirectUri: process.env.VUE_APP_redirectUri as string,
    },
    recommandUsers: testRecommandUsers,
    hotBuzz: testHotBuzz,
    CourseType: {
      hot: "tutorials",
      lastNew: "lastNew",
    },
    webConfigRefreshTime: 60 * 1 * 1000,
    nodeName: "Metaid101Config-03dfc7463fa",
    parentNodeName: "Metaid101Config",
    webConfigMetaId:
      "679b9bf2a3b36f40c7b83880962935529d87005a054a985a2c48e607b92b25b4",
    subjectFiling: "粤ICP备19109852号",
    subjectFilingUrl: "https://beian.miit.gov.cn",
    autoSaveArtTime: 60000,
    metaFileBrfcId: "6d3eaf759bbc",
    metaAccessContentBrfcId: "cc085a8742e7",
    metaAccessServiceConfigBrfcId: "c063b4d708d0",
    metaAccessBrfcId: "1556560901fe",
    metaAccessPayBrfcId: "7f6983393f0e",
    simpleSubscribeBrfcId: "93e5a8378132",
    simpleCancleSubscribeBrfcId: "4f6b2e6f9e78",
    main_net: main_network,
    simpleSubscribeAmount: 0.0001,
    simpleSubscribeFee: 0.0001,
    tablePage: 10,
  },
  test: {
    // baseUrl: main_network ? "https://dev.showmoney.io" : "",
    baseUrl: main_network
      ? "https://testmetaidbrowser.showpay.top"
      : "https://testmetaidbrowser.showpay.top",
    showMoneyUrl: main_network ? "https://test.showmoney.io" : "",
    metaFileServiceUrl: main_network ? "https://testshowman.showpay.io" : "",
    metaIdServiceUrl: main_network ? "https://test.showmoney.io/api" : "",
    metaIdCenterUrl: main_network ? "https://test.showmoney.app/tag" : "",
    basePath: "/",
    metaIdTag: "testshowid",
    env: type,
    projectAddress: main_network ? "15XtDJMeaMAafuVHdxS7Vvu6F8uXF1EU4h" : "",
    payCurrency: "usd",
    simpleBlogFee: 0.0001,
    payLikeAmount: 0.0001,
    payLikeFee: 0.0001,
    payLikeBrfcId: "b4a118f94cf2",
    payCommentFee: 0.0001,
    payCommentAmount: 0.0001,
    payCommentBrfcId: "70b8224e0559",
    payFollowAmount: 0.0001,
    payFollowFee: 0.0001,
    payFollowBrfcId: "8f23b51a047b",
    rePostAmount: 0.0001,
    rePostFee: 0.0001,
    rePostBrfcId: "b51e23269c75",
    // oauthSettings: {
    //   clientId: "bba72be8-f2e5-4c70-a513-49df103511e9",
    //   clientSecret: "4eb2f80d-7818-4e75-b7f1-392641a144fb",
    //   redirectUri: "https://dev.showmoney.io",
    // },
    // appOauthSettings: {
    //   clientId: "bba72be8-f2e5-4c70-a513-49df103511e9",
    //   clientSecret: "4eb2f80d-7818-4e75-b7f1-392641a144fb",
    //   redirectUri: "https://dev.showmoney.io",
    // },
    oauthSettings: {
      // clientId: main_network
      //   ? "9d8dca66-5ce8-402a-8c43-d95bed35c3ce"
      //   : // : "b42ce3de-61ae-4974-adc1-c8018daeea02",
      //     "74a439d5-4ef8-4e2a-b087-77c7e9cc0b91",
      // clientSecret: main_network
      //   ? "1e64dec2-1664-4244-b06f-219f5349a09e"
      //   : // : "370d2fc5-bbd1-441f-bd84-18d2a2ab4135",
      //     "d582f09f-d9e7-442a-a443-31e2b09c0dde",
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: process.env.VUE_APP_clientSecret as string,
      redirectUri: process.env.VUE_APP_redirectUri as string,
    },
    appOauthSettings: {
      // clientId: main_network
      //   ? "9d8dca66-5ce8-402a-8c43-d95bed35c3ce"
      //   : // : "b42ce3de-61ae-4974-adc1-c8018daeea02",
      //     "74a439d5-4ef8-4e2a-b087-77c7e9cc0b91",
      // clientSecret: main_network
      //   ? "1e64dec2-1664-4244-b06f-219f5349a09e"
      //   : // : "370d2fc5-bbd1-441f-bd84-18d2a2ab4135",
      //     "d582f09f-d9e7-442a-a443-31e2b09c0dde",
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: process.env.VUE_APP_clientSecret as string,
      redirectUri: process.env.VUE_APP_redirectUri as string,
    },
    recommandUsers: testRecommandUsers,
    hotBuzz: testHotBuzz,
    CourseType: {
      hot: "tutorials",
      lastNew: "lastNew",
    },
    webConfigRefreshTime: 60 * 5 * 1000,
    nodeName: "Metaid101Config-03dfc7463fa",
    parentNodeName: "Metaid101Config",
    webConfigMetaId:
      "679b9bf2a3b36f40c7b83880962935529d87005a054a985a2c48e607b92b25b4",
    subjectFiling: "粤ICP备19109852号",
    subjectFilingUrl: "https://beian.miit.gov.cn",
    autoSaveArtTime: 60000,
    metaFileBrfcId: "6d3eaf759bbc",
    metaAccessContentBrfcId: "cc085a8742e7",
    metaAccessServiceConfigBrfcId: "c063b4d708d0",
    metaAccessBrfcId: "1556560901fe",
    metaAccessPayBrfcId: "7f6983393f0e",
    simpleSubscribeBrfcId: "93e5a8378132",
    simpleCancleSubscribeBrfcId: "4f6b2e6f9e78",
    main_net: main_network,
    simpleSubscribeAmount: 0.0001,
    simpleSubscribeFee: 0.0001,
    tablePage: 10,
  },
  prod: {
    baseUrl: main_network ? "https://scan.metaid.io" : "",
    showMoneyUrl: main_network ? "https://www.showmoney.app": "",
    metaFileServiceUrl: main_network ? "https://showman.showpay.io" : "",
    metaIdServiceUrl: main_network ? "https://scan.metaid.io/api" : "",
    metaIdCenterUrl: main_network ? "https://scan.metaid.io/tag" : "",
    basePath: "/",
    metaIdTag: "metaid",
    env: type,
    projectAddress: main_network ? "15XtDJMeaMAafuVHdxS7Vvu6F8uXF1EU4h" : "",
    payCurrency: "usd",
    simpleBlogFee: 0.01,
    payLikeAmount: 0.02,
    payLikeFee: 0.005,
    payLikeBrfcId: "b4a118f94cf2",
    payCommentAmount: 0.01,
    payCommentFee: 0.0025,
    payCommentBrfcId: "70b8224e0559",
    payFollowAmount: 0.05,
    payFollowFee: 0.0125,
    payFollowBrfcId: "8f23b51a047b",
    rePostAmount: 0.01,
    rePostFee: 0.0025,
    rePostBrfcId: "b51e23269c75",
    oauthSettings: {
      // clientId: "metaidBrowser",
      // clientSecret: "metaidBrowser",
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: process.env.VUE_APP_clientSecret as string,
      redirectUri: process.env.VUE_APP_redirectUri as string,
      // clientId: '97a7d204-ddc6-4476-801e-e2f841129f6e',
      // clientSecret: 'd8f45f03-764f-43b3-9960-3866ad72fcf1',
      // redirectUri: 'https://lock.showmoney.app'
    },
    appOauthSettings: {
      // clientId: "metaidBrowser",
      // clientSecret: "metaidBrowser",
      clientId: process.env.VUE_APP_clientId as string,
      clientSecret: process.env.VUE_APP_clientSecret as string,
      redirectUri: process.env.VUE_APP_redirectUri as string,
    },
    recommandUsers: prodRecommandUsers,
    hotBuzz: prodHotBuzz,
    CourseType: {
      hot: "tutorials",
      lastNew: "lastNew",
    },
    webConfigRefreshTime: 60 * 5 * 1000,
    nodeName: "Metaid101Config-03ef798b9b1",
    parentNodeName: "Metaid101Config",
    webConfigMetaId:
      "a143b240db2a0daec1032fbed8fd1173045e2f43679feb96bf009872f95a7f89",
    subjectFiling: "粤ICP备19109852号",
    subjectFilingUrl: "https://beian.miit.gov.cn",
    autoSaveArtTime: 60000,
    metaFileBrfcId: "6d3eaf759bbc",
    metaAccessContentBrfcId: "cc085a8742e7",
    metaAccessServiceConfigBrfcId: "c063b4d708d0",
    metaAccessBrfcId: "1556560901fe",
    metaAccessPayBrfcId: "7f6983393f0e",
    simpleSubscribeBrfcId: "93e5a8378132",
    simpleCancleSubscribeBrfcId: "4f6b2e6f9e78",
    main_net: main_network,
    simpleSubscribeAmount: 0.01,
    simpleSubscribeFee: 0.0025,
    tablePage: 10,
  },
};
config[type].mcBlockTablePageMaxSize = 300;
config[type].mcBlockSize = Math.pow(10, 8);
export default config[type] as ConfigTypes;
